import { ArrowRight } from 'lucide-react';
import React, { useState } from 'react';

export default function Hire() {

    const [data, setData] = useState({
        name: "",
        email: "",
        type: "",
        comments: "",
    });

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch("https://ark-platforms.onrender.com/quote", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

            const result = await response.json();
            if (response.ok) {
                alert("Formulario enviado exitosamente.");
                // Limpiar los campos después de enviar
                setData({
                    name: "",
                    email: "",
                    type: "",
                    comments: "",
                });
            } else {
                alert(`Error: ${result.error}`);
            }
        } catch (error) {
            console.error("Error:", error);
            alert("Hubo un error al enviar el formulario.");
        }
    };

    return (
        <div>
            <section>
                {/* Container */}
                <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-20">
                    {/* Component */}
                    <div className="grid items-start gap-8 sm:gap-20 lg:grid-cols-2">
                        <div className="max-w-3xl">
                            {/* Title */}
                            <h2 className="mb-2 text-3xl font-bold md:text-5xl text-indigo-950">
                                Let's build something exciting together!
                            </h2>
                            <p className="my-4 max-w-lg pb-4 text-sm text-gray-500 sm:text-base md:mb-6 lg:mb-8">
                            Partner with us to transform your vision into reality. Our dedicated team of experts is ready to collaborate, innovate, and deliver exceptional solutions tailored to your specific needs. Whether you're looking to develop a new product, enhance your current offerings, or explore new opportunities, we’re here to make your project a success. Let's create something remarkable together!
                            </p>
                            {/* Testimonial */}
                            <div className="mb-4 flex items-center text-orange-500">
                                <svg
                                    className="h-8 w-8"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="100%"
                                    height="100%"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="currentColor"
                                        d="m12 17.27l4.15 2.51c.76.46 1.69-.22 1.49-1.08l-1.1-4.72l3.67-3.18c.67-.58.31-1.68-.57-1.75l-4.83-.41l-1.89-4.46c-.34-.81-1.5-.81-1.84 0L9.19 8.63l-4.83.41c-.88.07-1.24 1.17-.57 1.75l3.67 3.18l-1.1 4.72c-.2.86.73 1.54 1.49 1.08l4.15-2.5z"
                                    ></path>
                                </svg>
                                <svg
                                    className="h-8 w-8"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="100%"
                                    height="100%"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="currentColor"
                                        d="m12 17.27l4.15 2.51c.76.46 1.69-.22 1.49-1.08l-1.1-4.72l3.67-3.18c.67-.58.31-1.68-.57-1.75l-4.83-.41l-1.89-4.46c-.34-.81-1.5-.81-1.84 0L9.19 8.63l-4.83.41c-.88.07-1.24 1.17-.57 1.75l3.67 3.18l-1.1 4.72c-.2.86.73 1.54 1.49 1.08l4.15-2.5z"
                                    ></path>
                                </svg>
                                <svg
                                    className="h-8 w-8"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="100%"
                                    height="100%"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="currentColor"
                                        d="m12 17.27l4.15 2.51c.76.46 1.69-.22 1.49-1.08l-1.1-4.72l3.67-3.18c.67-.58.31-1.68-.57-1.75l-4.83-.41l-1.89-4.46c-.34-.81-1.5-.81-1.84 0L9.19 8.63l-4.83.41c-.88.07-1.24 1.17-.57 1.75l3.67 3.18l-1.1 4.72c-.2.86.73 1.54 1.49 1.08l4.15-2.5z"
                                    ></path>
                                </svg>
                                <svg
                                    className="h-8 w-8"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="100%"
                                    height="100%"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="currentColor"
                                        d="m12 17.27l4.15 2.51c.76.46 1.69-.22 1.49-1.08l-1.1-4.72l3.67-3.18c.67-.58.31-1.68-.57-1.75l-4.83-.41l-1.89-4.46c-.34-.81-1.5-.81-1.84 0L9.19 8.63l-4.83.41c-.88.07-1.24 1.17-.57 1.75l3.67 3.18l-1.1 4.72c-.2.86.73 1.54 1.49 1.08l4.15-2.5z"
                                    ></path>
                                </svg>
                                <svg
                                    className="h-8 w-8"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="100%"
                                    height="100%"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="currentColor"
                                        d="m12 17.27l4.15 2.51c.76.46 1.69-.22 1.49-1.08l-1.1-4.72l3.67-3.18c.67-.58.31-1.68-.57-1.75l-4.83-.41l-1.89-4.46c-.34-.81-1.5-.81-1.84 0L9.19 8.63l-4.83.41c-.88.07-1.24 1.17-.57 1.75l3.67 3.18l-1.1 4.72c-.2.86.73 1.54 1.49 1.08l4.15-2.5z"
                                    ></path>
                                </svg>
                            </div>
                            <p className="mb-8 max-w-lg text-sm text-gray-500 sm:text-base">
                            I am excited about the possibility of collaborating with you. With my experience in web development and design, I am committed to offering innovative solutions that grow your business. If you are looking for a reliable partner for your next project, contact me and let's build something amazing together.
                            </p>
                            <div className="flex items-center">
                                <img
                                loading={'lazy'}
                                    src="ceo.png"
                                    alt="Pablo Cuevas"
                                    width={0}
                                    height={0}
                                    className="mr-4 inline-block h-16 w-16 rounded-full border object-cover"
                                />
                                <div className="flex flex-col">
                                    <h6 className="text-base font-bold">Pablo Cuevas</h6>
                                    <p className="text-sm text-gray-500">CEO, Full Stack Developer & Designer </p>
                                </div>
                            </div>
                        </div>
                        <div className="mx-auto max-w-xl bg-gray-50 border p-8 rounded-3xl text-start">
                            <h3 className="text-2xl font-bold md:text-3xl text-indigo-950">Get a free quote</h3>
                            <p className="mx-auto mb-6 mt-4 max-w-lg text-sm text-gray-500 lg:mb-8">
                            Discover how we can transform your project with a free consultation. Simply send us the details and we will provide you with a personalized, no-obligation estimate.
                            </p>
                            {/* Form */}
                            <form
                                className=" mb-4 text-start"
                                onSubmit={handleSubmit}
                            >
                                <div>
                                    <label htmlFor="name-2" className="mb-1 font-medium text-indigo-950">
                                        Your Name
                                    </label>
                                    <input

                                        type="text"
                                        className="mb-4 block h-9 w-full rounded-md border border-solid mt-2 border-gray-200 /80 px-3 py-6 pl-4 text-sm text-black focus:outline-none focus:ring-0"
                                        value={data.name}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                name: e.target.value
                                            });
                                        }}

                                    />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="name-2" className="mb-1 font-medium text-indigo-950">
                                        Email Address
                                    </label>
                                    <input
                                        type="email"
                                        className="mb-4 block h-9 w-full rounded-md border border-solid mt-2 border-gray-200 /80 px-3 py-6 pl-4 text-sm text-black focus:outline-none focus:ring-0"
                                        value={data.email}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                email: e.target.value
                                            });
                                        }}

                                    />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="name-2" className="mb-1 font-medium text-indigo-950">
                                        Project Type
                                    </label>
                                    <select
                                        value={data.type}  // Asegúrate de que data.type tenga un valor inicial vacío si no hay uno
                                        className="mb-4 block w-full rounded-md border border-solid mt-2 border-gray-200 /80 px-3 py-4 pl-4 text-sm text-black focus:outline-none focus:ring-0"
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                type: e.target.value,
                                            });
                                        }}
                                    >
                                        <option value="" disabled selected hidden>Select One</option>  {/* Usamos value vacío por defecto */}
                                        <option value="App">Mobile Application</option>
                                        <option value="Software">Computer Software</option>
                                        <option value="Web">Website</option>
                                        <option value="Design">3D Design</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>

                                <div className="mb-5 md:mb-6 lg:mb-8 w-full">
                                    <label htmlFor="field-3" className="mb-1 font-medium text-indigo-950">
                                        Project Brief
                                    </label>
                                    <textarea
                                        placeholder=""
                                        maxLength="5000"
                                        name="field"
                                        value={data.comments}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                comments: e.target.value
                                            });
                                        }}

                                        className="mb-4 block h-28 resize-none w-full rounded-md border border-solid mt-2 border-gray-200 /80 px-3 py-6 pl-4 text-sm text-black focus:outline-none focus:ring-0"
                                    ></textarea>
                                </div>
                                <button type="submit" className="w-full flex flex-row justify-between items-center cursor-pointer rounded-md bg-black px-6 py-4 text-start font-medium text-white">
                                    <span>
                                    Get free quote
                                    </span>
                                    <ArrowRight className='size-4'/>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

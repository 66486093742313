import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PageTitle = ({ title, description }) => {
  const location = useLocation();

  useEffect(() => {
    // Update the document title
    document.title = title;

    // Update the meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", description);
    } else {
      const meta = document.createElement('meta');
      meta.name = "description";
      meta.content = description;
      document.head.appendChild(meta);
    }
  }, [location, title, description]);

  return null; // This component doesn't render anything
};

export default PageTitle;

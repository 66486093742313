import React, { useEffect, useState } from 'react';
import { useCart } from '../utils/cartUtils';
import { X } from 'lucide-react';
import StripeCheckout from './payment';

const CartPage = ({ setOpenCart }) => {
  const [cartItems, setCartItems] = useState([]);
  const { removeFromCart } = useCart();
  const url = 'api/price.json';

  const updateCartItems = (cart) => {
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Error al obtener los datos');
        }
        return response.json();
      })
      .then(data => {
        const updatedItems = cart.map(item => {
          const product = data.find(p => p.id === item.id);
          if (product) {
            return {
              ...product,
              quantity: 1,
            };
          }
          return item;
        });
        setCartItems(updatedItems);
      })
      .catch(error => {
        console.error('Hubo un error:', error);
      });
  };

  useEffect(() => {
    const handleStorageChange = () => {
      const cart = JSON.parse(localStorage.getItem('cart')) || [{}];
      updateCartItems(cart);
    };

    window.addEventListener('storage', handleStorageChange);
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    updateCartItems(cart);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const calculateSubtotal = () => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const calculateIVA = (subtotal) => {
    return subtotal * 0.21;  // IVA del 25%
  };

  const calculateTotal = (subtotal) => {
    return subtotal + calculateIVA(subtotal);
  };

  const removeItem = (id) => {
    removeFromCart(id);
  };

  const subtotal = calculateSubtotal();
  const iva = calculateIVA(subtotal);
  const total = calculateTotal(subtotal);

  const [isForPay, setIsForPay] = useState(null);

  const handleCheckout = () => {
    setIsForPay(true);    
  };

  return (
    <div className="cart-page container mx-auto p-8 max-w-5xl bg-white rounded-lg flex flex-col min-h-screen">
      <div className='w-full h-full'>
        <div className='w-full flex flex-row justify-between items-start'>
          <h1 className="text-2xl md:text-3xl text-gray-900 mb-8">My Cart</h1>
          <button onClick={() => setOpenCart(false)} className='w-10 h-10 bg-gray-200 flex justify-center items-center'>
            <X />
          </button>
        </div>

        <div className="cart-items overflow-y-auto flex-grow max-h-[60vh] w-full pb-20 mb-8">
          {
            isForPay ? <div className='w-full flex flex-col justify-center items-center h-full py-10'>
              <div class="loader">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              Processing...
            </div> : <>
              {cartItems.length > 0 ? (
                cartItems.map((item) => (
                  <div key={item.id} className='w-full h-auto p-4 border my-2 rounded-lg'>
                    <div className='w-full flex flex-row justify-between items-center'>
                      <h1 className='text-lg w-1/2 truncate'>{item.name}</h1>
                      <h1 className='text-lg w-1/2 text-end'>{(item.price * item.quantity).toFixed(2)} USD</h1>
                    </div>
                    <div className='w-full flex flex-row justify-between items-center'>
                      <p className='text-xs text-gray-500 truncate'>{item.description}</p>
                    </div>
                    <button onClick={() => removeItem(item.id)}>
                      <small className='text-red-600'>Remove</small>
                    </button>
                  </div>
                ))
              ) : (
                <p className="text-center text-lg text-gray-500">Your cart is empty.</p>
              )}</>
          }

        </div>

        <div className="fixed bottom-0 left-0 w-full bg-white py-4 px-8 shadow-md border-t border-gray-200 space-y-2">
          {
            isForPay ? <>
              <StripeCheckout totalAmount={total} />
            </> : <>
              <div className="flex justify-between items-center text-sm text-gray-900">
                <span>Subtotal:</span>
                <span>{subtotal.toFixed(2)} USD</span>
              </div>
              <div className="flex justify-between items-center text-sm text-gray-900">
                <span>IVA (21%):</span>
                <span>{iva.toFixed(2)} USD</span>
              </div>
              <div className="flex justify-between items-center text-xl font-bold text-gray-900">
                <span>Total:</span>
                <span>{total.toFixed(2)} USD</span>
              </div>
              <div className="mt-6">
                <button
                  onClick={handleCheckout}  // Llamamos la función para proceder al pago
                  className="w-full bg-black text-white py-4 px-8 rounded-md hover:bg-gray-800 transition duration-200"
                >
                  Proceed to Checkout
                </button>
              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default CartPage;

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="mt-auto w-full max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 mx-auto">
      <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-6 mb-10">
        <div className="col-span-full hidden lg:col-span-1 lg:block">
          <Link
            to={"/"}
            className="flex-none font-medium text-xl text-black focus:outline-none focus:opacity-80"
            aria-label="Brand"
          >
            <img src="logo.svg" alt="" className="w-20 h-20"/>
            Ark Platforms, Inc
          </Link>
          <p className="mt-3 text-xs sm:text-sm text-gray-600">
            © 2021 - {new Date().getFullYear()} Ark Platforms, Inc.
          </p>
        </div>

        <div>
          <h4 className="text-xs font-semibold text-gray-900 uppercase">
            Our Products
          </h4>

          <div className="mt-3 grid space-y-3 text-sm">
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 focus:outline-none focus:text-gray-800"
                to={"/app"}
              >
                Apps
              </Link>
            </p>
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 focus:outline-none focus:text-gray-800"
                to={"/software"}
              >
                Software
              </Link>
            </p>
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 focus:outline-none focus:text-gray-800"
                to={"/web"}
              >
                Webs
              </Link>
            </p>
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 focus:outline-none focus:text-gray-800"
                to={"/our-tools"}
              >
                Tools by Ark Platforms, Inc
              </Link>
            </p>
          </div>
        </div>

        <div>
          <h4 className="text-xs font-semibold text-gray-900 uppercase">Company</h4>

          <div className="mt-3 grid space-y-3 text-sm">
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 focus:outline-none focus:text-gray-800"
                to={"/about-us"}
              >
                About us
              </Link>
            </p> 
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 focus:outline-none focus:text-gray-800"
                to={"https://blog.ark-platforms.com"}
              >
                Blog
              </Link>
            </p>
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 focus:outline-none focus:text-gray-800"
                to={"https://carrers.ark-platforms.com/"}
              >
                Carrers
              </Link>{" "}
              <span className="inline text-blue-600">— We're hiring</span>
            </p>
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 focus:outline-none focus:text-gray-800"
                to={"/affiliates"}
              >
                Affiliates
              </Link>
            </p>
          </div>
        </div>

        <div>
          <h4 className="text-xs font-semibold text-gray-900 uppercase">
            Resources
          </h4>

          <div className="mt-3 grid space-y-3 text-sm">
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 focus:outline-none focus:text-gray-800"
                to={"https://forum.ark-platforms.com"}
              >
                Community
              </Link>
            </p>
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 focus:outline-none focus:text-gray-800"
                to={"/contact-us"}
              >
                Help & Support
              </Link>
            </p>
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 focus:outline-none focus:text-gray-800"
                to={"https://status.ark-platforms.com"}
              >
                Status
              </Link>
            </p>
          </div>
        </div>

        <div>
          <h4 className="text-xs font-semibold text-gray-900 uppercase">
            Developers
          </h4>

          <div className="mt-3 grid space-y-3 text-sm">
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 focus:outline-none focus:text-gray-800"
                to={"https://apis.ark-platforms.com"}
              >
                API's
              </Link>
            </p>
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 focus:outline-none focus:text-gray-800"
                to={"https://forum.ark-platforms.com"}
              >
                Ark Forum
              </Link>{" "}
              <span className="inline text-blue-600">— New</span>
            </p>
          </div>

          <h4 className="mt-7 text-xs font-semibold text-gray-900 uppercase">
            Industries
          </h4>

          <div className="mt-3 grid space-y-3 text-sm">
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 focus:outline-none focus:text-gray-800"
                to={"https://bank.ark-platforms.com"}
              >
                Financial Services
              </Link>
            </p>
            <p>
              <Link
                className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 focus:outline-none focus:text-gray-800"
                to={"https://edu.ark-platforms.com"}
              >
                Education
              </Link>
            </p>
          </div>
        </div>
      </div>

      <div className="pt-5 mt-5 border-t border-gray-200">
        <div className="sm:flex sm:justify-between sm:items-center">
          <div className="flex flex-wrap items-center gap-3">

            <div className="text-sm flex flex-col md:flex-row lg:flex-row gap-4">
              <Link
                className="text-gray-600 hover:text-gray-800 focus:outline-none focus:text-gray-800"
                to={"/terms"}
              >
                Terms & Conditions
              </Link>
              <Link
                className=" text-gray-600 hover:text-gray-800 focus:outline-none focus:text-gray-800"
                to={"/privacy"}
              >
                Privacy Policy
              </Link>
              <Link
                className=" text-gray-600 hover:text-gray-800 focus:outline-none focus:text-gray-800"
                to={"/lpp"}
              >
                Default Management Policy
              </Link>
              <Link
                className=" text-gray-600 hover:text-gray-800 focus:outline-none focus:text-gray-800"
                to={"/sc-policy"}
              >
                Services and Contracts Policy
              </Link>
            </div>
          </div>

          <div className="flex flex-wrap justify-between items-center gap-3">
            <div className="mt-3 sm:hidden">
              <Link
                className="flex-none font-medium text-xl text-black focus:outline-none focus:opacity-80"
                to={"/"}
                aria-label="Brand"
              >
                <img src="logo.svg" alt="" className="w-20 h-20" />

                Ark Platforms, Inc
              </Link>
              <p className="mt-1 text-xs sm:text-sm text-gray-600">
                © 2024 Ark Platforms, Inc.
              </p>
            </div>

          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

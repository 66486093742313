import React, { useState } from "react";
import axios from 'axios';

const PricingSection = () => {

    const [domain, setDomain] = useState('');
    const [availability, setAvailability] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const checkDomain = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await axios.get(`https://www.whoisxmlapi.com/whoisserver/WhoisService`, {
                params: {
                    apiKey: 'at_zOgI4lVomwOeZA9xejaISo1RC2YYU', // Reemplaza con tu clave API
                    domainName: domain,
                    outputFormat: 'JSON'
                }
            });

            const isAvailable = response.data.WhoisRecord.registryData.rawText;

            const match = isAvailable.match(/No match for domain ".*"/);

            if (match) {
                setAvailability(true);
            } else {
                setAvailability(false);
            }
        } catch (error) {
            setError('Error checking domain availability. Please try again later.');
            console.error('Error checking domain availability:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <section className="py-16 mb-16" id="domain">
            <div className="max-w-7xl mx-auto px-5 sm:px-10 md:px-12 lg:px-5">
                <div className="flex flex-col  space-y-12">
                    <div className="flex gap-10 flex-col items-center">
                        <div className="mx-auto max-w-3xl text-center space-y-8">
                            <span className="border border-gray-200  px-3 py-0.5 rounded-full bg-gray-100  text-gray-800 ">
                                Pricing
                            </span>
                            <h1 className="text-3xl md:text-4xl xl:text-5xl text-gray-900  font-bold ">
                                Our pricing
                            </h1>
                        </div>
                        <p className="text-gray-700  text-center max-w-2xl mx-auto">
                            Affordable and transparent pricing for all your domain needs. Choose from flexible plans to find the perfect domain for your business at a price that suits your budget.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 gap-8 items-center lg:grid-cols-3 md:grid-cols-1">
                        <div className="bg-white  p-5 sm:p-6 md:p-8 xl:p-10 border border-gray-300 /80 md:hauto flex flex-col rounded-3xl">
                            <span className="font-medium text-2xl text-gray-800 ">
                                Basic Domain
                            </span>
                            <small>
                                (1) One Month Free
                            </small>
                            <div className="mt-2 text-gray-900 text-2xl md:text-3xl font-bold">
                                20€
                            </div>
                            <p className="mt-5 sm:mt-6 text-gray-700 ">
                                This plan include:
                            </p>
                            <ul className="flex flex-col space-y-3 mt-5 sm:mt6 text-gray-600 ">
                                <li className="flex items-center gap-x-3">
                                    <span className="text-blue-600 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                            <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    Domain for 1 Year
                                </li>
                                <li className="flex items-center gap-x-3">
                                    <span className="text-blue-600 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                            <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    Website
                                </li>
                                <li className="flex items-center gap-x-3">
                                    <span className="text-blue-600 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                            <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    1 Email Address
                                </li>
                                <li className="flex items-center gap-x-3">
                                    <span className="text-blue-600 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                            <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    Support 24/7
                                </li>
                            </ul>
                            <div className="mt-5 sm:mt-6">
                                <button className="bg-black hover:bg-opacity-90 text-white transition-colors ease-leaner h-12 rounded-full px-6
                          w-full flex items-center gap-x-3 justify-center">
                                    Get started <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                            <path fillRule="evenodd" d="M3 10a.75.75 0 01.75-.75h10.638L10.23 5.29a.75.75 0 111.04-1.08l5.5 5.25a.75.75 0 010 1.08l-5.5 5.25a.75.75 0 11-1.04-1.08l4.158-3.96H3.75A.75.75 0 013 10z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="bg-black p-5 relative sm:p-6 md:p-8 xl:p-10 rounded-3xl border border-gray-800  flex flex-col">
                            <span className="font-semibold text-2xl text-gray-100">
                                Pro Domain
                            </span>
                            <small className="text-gray-100">
                                (3) Three Month Free
                            </small>
                            <div className="text-white mt-2 text-2xl md:text-3xl font-bold">
                                50€
                            </div>
                            <p className="mt-5 sm:mt-6 text-gray-200">
                                This plan include
                            </p>
                            <ul className="flex flex-col space-y-3 mt-5 sm-mt6 text-gray-300">
                                <li className="flex items-center gap-x-3">
                                    <span className="text-blue-600 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                            <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    Free domain for one year
                                </li>
                                <li className="flex items-center gap-x-3">
                                    <span className="text-blue-600 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                            <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    Website
                                </li>
                                <li className="flex items-center gap-x-3">
                                    <span className="text-blue-600 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                            <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    10 Email Address
                                </li>
                                <li className="flex items-center gap-x-3">
                                    <span className="text-blue-600 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                            <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    Support 24/7
                                </li>
                                <li className="flex items-center gap-x-3">
                                    <span className="text-blue-600 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                            <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    2GB per Email
                                </li>
                                <li className="flex items-center gap-x-3">
                                    <span className="text-blue-600 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                            <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    Free Certificated SSL
                                </li>
                            </ul>
                            <div className="mt-5 sm-mt6">
                                <button className="bg-white hover:opacity-90 text-gray-900 transition-colors ease-leaner h-12 rounded-full px-6
                          w-full flex items-center gap-x-3 justify-center">
                                    Get started <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                            <path fillRule="evenodd" d="M3 10a.75.75 0 01.75-.75h10.638L10.23 5.29a.75.75 0 111.04-1.08l5.5 5.25a.75.75 0 010 1.08l-5.5 5.25a.75.75 0 11-1.04-1.08l4.158-3.96H3.75A.75.75 0 013 10z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="bg-white  p-5 sm:p-6 md:p-8 xl:p-10 border border-gray-300 /80 md:hauto flex flex-col rounded-3xl">
                            <span className="font-semibold text-2xl text-gray-800 ">
                                Premium Domain
                            </span>
                            <small className="text-gray-900">
                                (6) Six Months Free
                            </small>
                            <div className="mt-2 text-gray-900  text-2xl md:text-3xl font-bold">
                                80€
                            </div>
                            <p className="mt-5 sm:mt-6 text-gray-700 ">
                                This plan include
                            </p>
                            <ul className="flex flex-col space-y-3 mt-5 sm:mt6 text-gray-600 ">
                                <li className="flex items-center gap-x-3">
                                    <span className="text-blue-600 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                            <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    All Pro Domain
                                </li>
                                <li className="flex items-center gap-x-3">
                                    <span className="text-blue-600 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                            <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    Website
                                </li>
                                <li className="flex items-center gap-x-3">
                                    <span className="text-blue-600 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                            <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    Email Microsoft® Exchange 2019
                                </li>
                                <li className="flex items-center gap-x-3">
                                    <span className="text-blue-600 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                            <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    Support 24/7
                                </li>
                                <li className="flex items-center gap-x-3">
                                    <span className="text-blue-600 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                            <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    50GB per Email
                                </li>
                            </ul>
                            <div className="mt-5 sm:mt-6">
                                <button className="bg-black hover:bg-opacity-90 text-white transition-colors ease-leaner h-12 rounded-full px-6
                          w-full flex items-center gap-x-3 justify-center">
                                    Get started <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                            <path fillRule="evenodd" d="M3 10a.75.75 0 01.75-.75h10.638L10.23 5.29a.75.75 0 111.04-1.08l5.5 5.25a.75.75 0 010 1.08l-5.5 5.25a.75.75 0 11-1.04-1.08l4.158-3.96H3.75A.75.75 0 013 10z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div >
                        <h1 className="text-xl md:text-xl xl:text-xl text-gray-900 font-semibold ">
                            Find your domain:
                        </h1>
                        <div className="w-full flex flex-col md:flex-row justify-between items-center gap-4">
                            <input type="text" onChange={(e) => setDomain(e.target.value)} value={domain} className="w-full md:w-1/2 border my-3 py-4 border-gray-300 /80 text-black px-3 focus:outline-none focus:ring-0 rounded-xl" placeholder="abc.com" />
                            <button onClick={checkDomain} disabled={loading} className="w-full md:w-1/2 bg-black text-white py-4 px-6 rounded-xl flex flex-row justify-between">
                                <span className="font-semibold text-base">
                                    {loading ? 'Checking...' : 'Check Availability'}
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-white" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-search"><circle cx="11" cy="11" r="8" /><path d="m21 21-4.3-4.3" /></svg>
                            </button>
                        </div>
                        <p>
                            {availability !== null && (
                                <p>{availability ?
                                    <>
                                        <div className="my-2">
                                            <span className="py-1 px-2 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full ">
                                                <svg className="shrink-0 size-3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                    <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"></path>
                                                    <path d="m9 12 2 2 4-4"></path>
                                                </svg>
                                                This domain is available
                                            </span>
                                        </div>

                                    </>
                                    :
                                    <>
                                        <div className="my-2">
                                            <span className="py-2 px-4 inline-flex items-center gap-x-1 text-xs font-medium bg-red-100 text-red-800 rounded-full ">
                                                <svg className="shrink-0 size-3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                    <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3Z"></path>
                                                    <path d="M12 9v4"></path>
                                                    <path d="M12 17h.01"></path>
                                                </svg>
                                                This domain is not available
                                            </span>
                                        </div>
                                    </>
                                }</p>
                            )}</p>
                        {error && <p>{error}</p>}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PricingSection
// FirebaseConfig.jsx
import { initializeApp } from 'firebase/app';  // Asegúrate de importar correctamente initializeApp
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Tu configuración de Firebase
const firebaseConfig = {
    apiKey: "AIzaSyCRzWd6PLuCw6NZGclDaUpoVB8MiGSs-1g",
    authDomain: "arkplatformsinc.firebaseapp.com",
    databaseURL: "https://arkplatformsinc-default-rtdb.firebaseio.com",
    projectId: "arkplatformsinc",
    storageBucket: "arkplatformsinc.appspot.com",
    messagingSenderId: "397134522497",
    appId: "1:397134522497:web:0385c4891449936d590580"
};

// Inicializar Firebase
const FIREBASE_APP = initializeApp(firebaseConfig);

// Exportar los servicios necesarios
export const AUTH = getAuth(FIREBASE_APP);
export const db = getFirestore(FIREBASE_APP);
export const storage = getStorage(FIREBASE_APP);

export default function Terms() {

    const termsAndConditions = [
        {
            id: 1,
            title: "Introduction",
            content: "Welcome to Ark Platforms, Inc. These Terms and Conditions govern your use of our website and services. By accessing or using our services, you agree to comply with and be bound by these terms. If you do not agree with any part of these terms, you must not use our services."
        },
        {
            id: 2,
            title: "Acceptance of Terms",
            content: "By using our services, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. We may update these terms from time to time, and your continued use of our services signifies your acceptance of any changes."
        },
        {
            id: 3,
            title: "Account Registration and Responsibilities",
            content: "To access certain features of our services, you may need to register for an account. You are responsible for providing accurate and complete information during registration and for maintaining the confidentiality of your account credentials. You agree to notify us immediately of any unauthorized use of your account or any other breach of security."
        },
        {
            id: 4,
            title: "User Conduct",
            content: "You agree to use our services in a lawful manner and only for the intended purposes. You must not engage in any activity that could harm or disrupt our services, or that could violate the rights of others. Prohibited activities include but are not limited to: unauthorized access, distribution of malware, and harassment."
        },
        {
            id: 5,
            title: "Intellectual Property Rights",
            content: "All content provided by Ark Platforms, Inc., including but not limited to text, graphics, logos, and software, is owned by Ark Platforms, Inc. and is protected by intellectual property laws. You may not copy, modify, distribute, or create derivative works from our content without our express written permission."
        },
        {
            id: 6,
            title: "Privacy Policy",
            content: "We value your privacy and are committed to protecting your personal information. Our Privacy Policy outlines how we collect, use, and safeguard your data. By using our services, you consent to the practices described in our Privacy Policy."
        },
        {
            id: 7,
            title: "Limitation of Liability",
            content: "Ark Platforms, Inc. will not be liable for any indirect, incidental, consequential, or punitive damages arising out of or in connection with your use of our services. Our total liability to you for any claim arising from these terms shall not exceed the amount you have paid for our services."
        },
        {
            id: 8,
            title: "Indemnification",
            content: "You agree to indemnify, defend, and hold harmless Ark Platforms, Inc., its affiliates, and their respective officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, or expenses arising out of or in connection with your use of our services or violation of these terms."
        },
        {
            id: 9,
            title: "Termination",
            content: "We reserve the right to terminate or suspend your access to our services at our sole discretion, without prior notice, for any reason, including if you violate these terms. Upon termination, your right to use our services will cease immediately."
        },
        {
            id: 10,
            title: "Governing Law and Dispute Resolution",
            content: "These Terms and Conditions shall be governed by and construed in accordance with the laws of Bogotá, Colombia. Any disputes arising out of or in connection with these terms shall be resolved in the courts of Bogotá."
        },
        {
            id: 11,
            title: "Changes to Terms",
            content: "We may update these Terms and Conditions periodically. When we do, we will post the revised terms on our website. It is your responsibility to review these terms regularly. Your continued use of our services after any changes indicates your acceptance of the revised terms."
        },
        {
            id: 12,
            title: "Contact Information",
            content: "If you have any questions or concerns regarding these Terms and Conditions, please contact us at: info@ark-platforms.com or via our contact form on our website."
        }
    ];


    return (
        <section>
            <div className="flex flex-col items-center justify-center px-5 md:px-10">
                {/* Title Container */}
                <div className="flex h-auto min-w-[90vw] flex-col items-center justify-end bg-gray-100 rounded-b-3xl py-6 md:h-64">
                    <div className="flex flex-col items-center gap-y-4 py-5">
                        <h1 className="text-3xl font-bold md:text-5xl">Terms & Conditions</h1>
                        <p className="text-sm text-[#808080] sm:text-base">Last Updated as of September 18, 2024</p>
                    </div>
                </div>
                {/* Content Container */}
                <div className="mx-auto w-full max-w-5xl py-12 md:py-16 lg:py-20">
                    {/* Component */}
                    <div className="flex flex-col items-center gap-y-14">
                        <p className="max-w-full text-start text-sm sm:text-base">Terms and conditions are a set of rules and guidelines that govern the use of a service or product. These established the rights and responsibilities of both the provider and the user, and define the rules for the use of the service, data management and dispute resolution. They are essential to ensure clear understanding and mutual agreement between the parties, thus protecting both the company and its customers.</p>
                        <div className="flex min-w-full flex-col gap-y-10">
                            <div className="flex min-w-full py-4 [border-bottom:1px_solid_rgb(226,_226,_226)]">
                                <h6 className="text-base font-bold">GENERAL TERMS &amp; CONDITIONS</h6>
                            </div>
                            <div className="flex flex-col gap-y-10">
                                <div className="flex min-w-full flex-col items-start gap-y-6">
                                    {termsAndConditions.map(term => (
                                        <div key={term.id} className="flex flex-col items-start gap-y-3">
                                            <p className="text-xl font-semibold">{term.title}</p>
                                            <p className="text-sm">{term.content}</p>
                                        </div>
                                    ))}
                                </div>
                                <div className="min-h-[1px] min-w-full bg-[#e2e2e2]"></div>
                                <p className="text-sm">By accessing, browsing, or utilizing any design services, communication channels, or materials provided by Ark Platforms, Inc, including but not limited to graphic design, web design, branding, illustration, and user interface design, whether through our website, email, phone, or any other means, you expressly acknowledge, understand, and agree that you have carefully read, comprehended, and fully consent to be legally bound by all the provisions, terms, and conditions set forth in these Terms of Service, including any additional agreements, policies, guidelines, or amendments referenced or incorporated herein.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Si estás usando react-router-dom

const ScrollToTop = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    // Lleva el scroll al inicio de la página cuando cambia la ruta o el contenido
    window.scrollTo(0, 0);
  }, [location, children]); // Escucha cambios en la ruta y los children

  return null;
};

export default ScrollToTop;

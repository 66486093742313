export default function News () {
    return (
        <section>     
      {/* Container */}
      <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-20">    
        {/* Component */}
        <div className="grid justify-items-center gap-4 rounded-3xl bg-gray-100 p-8 sm:p-10 md:grid-cols-[1.5fr_1fr] md:justify-items-start md:p-16">
          <div className="text-center md:text-start">
            <h2 className="mb-2 text-3xl font-bold md:text-5xl">
            Get the Latest
            </h2>
            <p className="max-w-md text-sm sm:test-base text-gray-500">
            Stay informed with our latest updates, insights, and offers. Sign up for our newsletter to receive exclusive content and be the first to know about our newest features and announcements.
            </p>
          </div>
          <div className="max-w-md sm:max-w-full">
            <form
              name="email-form"
              method="get"
              className="relative mx-auto mb-4 flex w-full flex-col items-center justify-center sm:flex-row"
            >
              <div className="relative w-full">
              <input
                type="email"
                className="mb-4 block h-9 w-full rounded-md border border-solid border-gray-300 /80 px-3 py-6 pl-4 text-sm text-black focus:outline-none focus:right-0"
                placeholder="Enter your email"
              />
              <input
                type="submit"
                value="Notify me"
                className="absolute right-2 w-1/3 top-2 cursor-pointer rounded-md bg-black px-3 py-2 text-sm font-semibold text-white "
              />
              </div>
            </form>
            <p className="text-sm text-gray-500 sm:text-base">
              Join a community of over <span>5000+ Webflow Developers</span>
            </p>
          </div>
        </div>
      </div>
    </section>
    )
}
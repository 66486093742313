import { useState } from "react";
import ScrollToTop from "../components/ScrollToTop";
import { Link } from "react-router-dom";

export default function Contact() {
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    companyWebsite: "",
    email: "",
    message: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("https://ark-platforms.onrender.com/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      if (response.ok) {
        alert("Formulario enviado exitosamente.");
        // Limpiar los campos después de enviar
        setData({
          firstName: "",
          lastName: "",
          companyWebsite: "",
          email: "",
          message: "",
        });
      } else {
        alert(`Error: ${result.error}`);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Hubo un error al enviar el formulario.");
    }
  };

  return (
    <section>
      <ScrollToTop />
      <div className="mx-auto max-w-7xl px-5 py-16 md:px-10 md:py-20">
        <h2 className="text-3xl font-semibold md:text-5xl text-indigo-950">
          Contact Us
        </h2>
        <p className="mb-8 mt-4 max-w-3xl text-gray-500 md:mb-12 lg:mb-16">
          If you have problems, any questions or want to know something that you
          have not been able to find, complete this form and we will contact you
          within 24 to 48 hours
        </p>
        <form onSubmit={handleSubmit} name="wf-form-name" className="mx-auto mb-4 text-left">
          <div className="mb-0 grid w-full grid-cols-2 gap-4">
            <div>
              <input
                id="firstName"
                type="text"
                placeholder="First Name"
                className="w-full border my-3 py-4 border-gray-300 /80 text-black px-3 focus:outline-none focus:ring-0 rounded-xl"
                required
                value={data.firstName}
                onChange={(e) => setData({ ...data, firstName: e.target.value })}
              />
            </div>
            <div>
              <input
                id="lastName"
                placeholder="Last Name"
                type="text"
                className="w-full border my-3 py-4 border-gray-300 /80 text-black px-3 focus:outline-none focus:ring-0 rounded-xl"
                required
                value={data.lastName}
                onChange={(e) => setData({ ...data, lastName: e.target.value })}
              />
            </div>
          </div>
          <div >
            <input
              id="companyWebsite"
              type="url"
              placeholder="Company Website"
              className="w-full border my-3 py-4 border-gray-300 /80 text-black px-3 focus:outline-none focus:ring-0 rounded-xl"
              value={data.companyWebsite}
              onChange={(e) =>
                setData({ ...data, companyWebsite: e.target.value })
              }
            />
          </div>
          <div>
            <input
              id="email"
              type="email"
              placeholder="Email address"
              className="w-full border my-3 py-4 border-gray-300 /80 text-black px-3 focus:outline-none focus:ring-0 rounded-xl"
              required
              value={data.email}
              onChange={(e) => setData({ ...data, email: e.target.value })}
            />
          </div>
          <div className="mb-5 md:mb-4 lg:mb-6">
            <textarea
              id="message"
              maxLength="5000"
              name="message"
              required
              placeholder="Message"
              value={data.message}
              onChange={(e) => setData({ ...data, message: e.target.value })}
              className="w-full border my-3 py-4 border-gray-300 /80 text-black px-3 focus:outline-none focus:ring-0 rounded-xl"
            ></textarea>
          </div>
          <label className="flex items-start cursor-pointer mb-1 justify-start pb-4">
            <div
              className={`relative w-12 h-7 transition duration-200 ease-linear rounded-full ${checked ? "bg-blue-600" : "bg-gray-100"
                }`}
            >
              <span
                className={`absolute left-1 top-1 transition duration-200 ease-in-out transform bg-white rounded-full w-5 h-5 ${checked ? "translate-x-5" : ""
                  }`}
              />
            </div>
            <input
              type="checkbox"
              className="hidden"
              checked={checked}
              onChange={handleChange}
            />
            <span
              className="ml-4 inline-block cursor-pointer text-sm max-w-48 md:max-w-full"
              htmlFor="checkbox-2"
            >
              By selecting this, you agree to the{" "}
              <Link to="/privacy" className="text-blue-500 font-medium">
                Privacy Policy
              </Link>
            </span>
          </label>
          <input
            type="submit"
            value="Send"
            disabled={checked ? false : true}
            className="inline-block md:float-right md:w-[40%] w-full rounded-md cursor-pointer bg-black px-6 py-4 text-center font-semibold text-white"
          />
        </form>
      </div>
    </section>
  );
}

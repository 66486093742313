export default function Tools () {
    return (
        <section>
      {/* Container */}
      <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-20">
        {/* Title */}
        <h2 className="text-center text-3xl font-bold md:text-5xl lg:text-left">
        Our Proprietary Tools for Your Use
        </h2>
        <p className="mb-8 mt-4 max-w-3xl text-center text-sm text-gray-500 sm:text-base md:mb-12 lg:mb-16 lg:text-left">
        Discover our suite of exclusive tools designed to enhance your business operations. Developed with precision and innovation, these tools are tailored to meet your needs and drive your success.
        </p>
        {/* Content */}
        <div className="grid justify-items-stretch md:mb-12 md:grid-cols-3 md:gap-4 lg:mb-16 lg:gap-6">
          <span
            className="relative  flex h-[500px] flex-col gap-4  px-4 py-8 [grid-area:1/1/2/2] md:p-0 md:[grid-area:1/1/2/4]"
          >
            <div className="absolute bottom-12 left-8 z-20 flex w-56 max-w-lg flex-col items-start rounded-xl bg-white p-6 sm:w-full md:bottom-[10px] md:left-[10px]">
              <div className="mb-4 rounded-full bg-blue-100 px-2 py-1.5">
                <p className="text-sm font-semibold text-blue-600">
                  Development
                </p>
              </div>
              <p className="mb-4 max-w-xs text-xl font-bold md:text-2xl">
                BeCode
              </p>
              <div className="flex flex-col text-sm text-gray-500 lg:flex-row">
                <p>code.ark-platforms.com</p>
                <p className="mx-2 hidden lg:block">-</p>
                <p>beta</p>
              </div>
            </div>
            <img
              src="images/ide.webp"
              alt=""
              className="inline-block h-full w-full object-cover rounded-3xl"
            />
          </span>
          <span
            className="flex flex-col gap-4 rounded-md px-4 py-8 md:p-0"
          >
            <img
              src="images/keyshield.webp"
              alt=""
              className="inline-block h-60 w-full object-cover rounded-3xl"
            />
            <div className="flex flex-col items-start py-4">
              <div className="mb-4 rounded-full bg-blue-100 px-2 py-1.5">
                <p className="text-sm font-semibold text-blue-600">
                  Security
                </p>
              </div>
              <p className="mb-4 text-xl font-bold md:text-2xl">
                Key Shield
              </p>
              <div className="flex flex-col text-sm text-gray-500 lg:flex-row">
                <p>Next Release</p>
                <p className="mx-2 hidden lg:block">-</p>
                <p>28 Oct, 2024</p>
              </div>
            </div>
          </span>
          <a
            href="https://dtwcloud.ark-platforms.com/"
            className="flex flex-col gap-4 rounded-md px-4 py-8 md:p-0"
          >
            <img
              src="images/web.webp"
              alt=""
              className="inline-block h-60 w-full object-cover rounded-3xl"
            />
            <div className="flex flex-col items-start py-4">
              <div className="mb-4 rounded-full bg-blue-100 px-2 py-1.5">
                <p className="text-sm font-semibold text-blue-600">
                  Business
                </p>
              </div>
              <p className="mb-4 text-xl font-bold md:text-2xl">
                DataWare
              </p>
              <div className="flex flex-col text-sm text-gray-500 lg:flex-row">
                <p>dtwcloud.ark-platforms.com</p>
                <p className="mx-2 hidden lg:block">-</p>
                <p>Available</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </section>
    )
}
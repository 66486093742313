const PageNotFound = () => {
    return (
        <>
            <div class="lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16">
                <div class="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
                    <div class="relative">
                        <div class="absolute">
                            <div class="">
                                <h1 class="my-2 text-gray-800 font-bold text-2xl">
                                Oops, looks like you've turned to the wrong page.
                                </h1>
                                <p class="my-2 text-gray-800">We&apos;re sorry, but the page you are trying to access is not available or doesn&apos;t exist</p>
                                <button onClick={() => window.history.back()} class="sm:w-full lg:w-auto my-2 border rounded md py-4 px-8 text-center bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-700 focus:ring-opacity-50">Go back</button>
                            </div>
                        </div>
                        <div>
                            <img loading={'lazy'} src="https://firebasestorage.googleapis.com/v0/b/arkplatformsinc.appspot.com/o/Ark%20Platforms%2C%20Inc%2F404.webp?alt=media&token=3770ce13-4911-4b28-b3c7-40f124f8341d" />
                        </div>
                    </div>
                </div>
                <div>
                    <img loading={'lazy'} src="https://firebasestorage.googleapis.com/v0/b/arkplatformsinc.appspot.com/o/Ark%20Platforms%2C%20Inc%2FGroup.webp?alt=media&token=5aced62b-e4e8-4ff6-8777-b4679c1cacdd" />
                </div>
            </div>
        </>
    );
};

export default PageNotFound;

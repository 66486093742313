import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PageTitle from '../components/PageTitle'; // Asegúrate de ajustar la ruta según tu estructura de archivos
import Home from '../pages/Home';
import PageNotFound from '../pages/PageNotFound';
import Hire from '../pages/Hire';
import Contact from '../pages/Contact';
import App from '../pages/App';
import Web from '../pages/Web';
import Software from '../pages/Software';
import Tools from '../pages/Tools';
import Design from '../pages/Design';
import About from '../pages/About-us';
import Terms from '../pages/Terms';
import Privacy from '../pages/Privacy';
import UploadDocuments from '../pages/Files';
import WorksPrivacy from '../pages/WorksPrivacy';
import Morosidad from '../pages/Morosidad';
import Affiliates from '../pages/Affiliates';

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        index
        element={
          <>
            <PageTitle title={"Ark Platforms, Inc - Home"} description={"We design your website or ecommerce page. We know perfectly the process that will lead your project to a success story. Custom web development agency for companies. More than 5 years of experience developing web pages that boost sales and conversions. Custom software development for companies Ark Platforms, Inc is a technology consultancy dedicated to the development of custom software for companies. Ark Platforms, Inc We develop custom software, business platforms, web and mobile applications, and dashboards. Our personalized approach ensures that each technological solution fits perfectly to your specific needs, optimizing your processes and enhancing [&hellip;]"} />
            <Home />
          </>
        }
      />
      <Route
        path="*"
        index
        element={
          <>
            <PageTitle title="Ark Platforms, Inc - Page Not Found" />
            <PageNotFound />
          </>
        }
      />
      <Route
        path="/hire-us"
        index
        element={
          <>
            <PageTitle title="Ark Platforms, Inc - Hire Us" description={"At Ark Platforms, Inc., we are dedicated to delivering exceptional solutions tailored to your needs. Our team of experienced professionals specializes in creating innovative software, cutting-edge web designs, and dynamic 3D visualizations. When you hire us, you gain access to a team committed to excellence, precision, and efficiency. We work closely with you to understand your goals and provide solutions that not only meet but exceed your expectations. Let us help you bring your vision to life and achieve your business objectives with unparalleled expertise and dedication."} />
            <Hire />
          </>
        }
      />
      <Route
        path="/contact-us"
        index
        element={
          <>
            <PageTitle title="Ark Platforms, Inc - Contact" description={"Get in touch with Ark Platforms, Inc. for all your software development, web design, and 3D visualization needs. Our dedicated team is here to assist you with inquiries, provide support, and discuss how we can bring your projects to life. Whether you have questions about our services, need a quote, or want to schedule a consultation, we're just a message away. Fill out the contact form below, or reach us directly through our email or phone. We look forward to connecting with you and exploring how we can help you achieve your business goals with our innovative solutions."} />
            <Contact />
          </>
        }
      />
      <Route
        path="/app"
        index
        element={
          <>
            <PageTitle title="Ark Platforms, Inc - Apps" description={"Discover top-notch app development services with Ark Platforms, Inc. Our team specializes in creating high-quality, user-friendly mobile and web applications tailored to your business needs. From conceptualization and design to development and deployment, we handle every aspect of the app development process. We use the latest technologies and best practices to ensure your app is robust, scalable, and meets your specific requirements. Whether you need a native mobile app, a cross-platform solution, or a web-based application, we are committed to delivering exceptional results that drive user engagement and business growth. Explore how our innovative app development solutions can transform your ideas into reality."} />
            <App />
          </>
        }
      />

      <Route
        path="/web"
        index
        element={
          <>
            <PageTitle title="Ark Platforms, Inc - Webs" description={"At Ark Platforms, Inc., we offer expert web development services to build and enhance your online presence. Our team delivers custom websites that are visually appealing, responsive, and optimized for performance. From initial design and development to ongoing support and maintenance, we ensure your website meets your business goals and exceeds user expectations. We utilize the latest web technologies and best practices to create solutions that are scalable, secure, and tailored to your unique needs. Whether you require a simple informational site, a complex e-commerce platform, or a dynamic web application, we are dedicated to providing innovative and effective web development solutions that drive success and growth."} />
            <Web />
          </>
        }
      />

      <Route
        path="/software"
        index
        element={
          <>
            <PageTitle title="Ark Platforms, Inc - Software" description={"At Ark Platforms, Inc., we specialize in creating cutting-edge software solutions tailored to your business needs. Our team of experienced developers builds robust and scalable software applications that streamline operations, enhance productivity, and drive innovation. From custom software development to system integration and ongoing support, we ensure your software aligns with your business goals and adapts to evolving market demands. We leverage the latest technologies and methodologies to deliver secure, user-friendly, and high-performance software that supports your strategic objectives and accelerates growth."} />
            <Software />
          </>
        }
      />

      <Route
        path="/our-tools"
        index
        element={
          <>
            <PageTitle title="Ark Platforms, Inc - Our tools" description={"Explore Ark Platforms, Inc.'s suite of powerful tools designed to optimize and elevate your business operations. Our proprietary tools are crafted to meet a wide range of industry needs, from streamlining workflows to enhancing data analysis and improving overall efficiency. Whether you're looking for advanced project management solutions, intuitive design tools, or robust analytics platforms, our tools are built with precision and innovation to help your business thrive. Discover how our solutions can transform your processes, boost productivity, and drive success in today’s competitive landscape."} />
            <Tools />
          </>
        }
      />

      <Route
        path="/design"
        index
        element={
          <>
            <PageTitle title="Ark Platforms, Inc - Design" description={"Discover the art and science of exceptional design with Ark Platforms, Inc. Our design services blend creativity with technical expertise to deliver visually stunning and user-centric solutions. From sleek website interfaces and engaging mobile app designs to innovative 3D visualizations and branding materials, we craft designs that captivate and convert. Our focus is on creating intuitive, aesthetically pleasing designs that not only reflect your brand's identity but also enhance user experience. Explore our design offerings to see how we can bring your vision to life and make a lasting impression in your industry."} />
            <Design />
          </>
        }
      />

      <Route
        path="/about-us"
        index
        element={
          <>
            <PageTitle title="Ark Platforms, Inc - About us" description={"At Ark Platforms, Inc., we are dedicated to driving innovation and excellence in digital solutions. Based in Bogotá and registered with the Chamber of Commerce, our mission is to empower businesses through cutting-edge technology and design. With a focus on software development, web design, and mobile applications, our expert team is committed to delivering high-quality, customized solutions that meet the unique needs of each client. Discover our journey, values, and the passionate professionals behind Ark Platforms, Inc. who make it all happen. Learn how we can partner with you to achieve your business goals and elevate your digital presence."} />
            <About />
          </>
        }
      />

      <Route
        path="/affiliates"
        index
        element={
          <>
            <PageTitle
              title="Ark Platforms, Inc - Affiliates"
              description="Join our network of affiliates and become part of our growing success. At Ark Platforms, Inc., we offer exciting opportunities for individuals and businesses to collaborate and thrive in the digital landscape. As an affiliate, you'll gain access to exclusive offers, commissions, and resources that empower you to succeed. Whether you're looking to promote cutting-edge software, web design services, or mobile applications, our team is here to support you every step of the way. Learn more about our affiliate program and how you can grow with us."
            />
            <Affiliates />
          </>
        }
      />

      <Route
        path="/terms"
        index
        element={
          <>
            <PageTitle title="Ark Platforms, Inc - Terms & Conditions" />
            <Terms />
          </>
        }
      />

      <Route
        path="/privacy"
        index
        element={
          <>
            <PageTitle title="Ark Platforms, Inc - Privacy Policy" />
            <Privacy />
          </>
        }
      />

      <Route
        path="/sc-policy"
        index
        element={
          <>
            <PageTitle title="Ark Platforms, Inc - Privacy Policy" />
            <WorksPrivacy />
          </>
        }
      />

      <Route
        path="/share/files"
        index
        element={
          <>
            <PageTitle title="Ark Platforms, Inc - Privacy Policy" />
            <UploadDocuments />
          </>
        }
      />

      <Route
        path="/lpp"
        index
        element={
          <>
            <PageTitle title="Ark Platforms, Inc - Late Payment Policy" />
            <Morosidad />
          </>
        }
      />
    </Routes>
  );
};

export default AppRoutes;

import { ArrowRight, Link } from "lucide-react"

const CtaSection = () => {
    return (
        <section className="py-12">
            <div className="max-w-7xl mx-auto px-5 sm:px-10 md:px-12 lg:px-5 relative">
                <div className="bg-white rounded-xl p-10 sm:p-14 !pb-0 flex flex-col md:flex-row gap-14 overflow-hidden">
                    <div className="space-y-8 max-w-lg md:max-w-none md:w-6/12 lg:w-2/5 md:py-8">
                        <h1 className="text-3xl font-bold text-gray-900 ">
                            Join +2k WordWide Users
                        </h1>
                        <p className="text-gray-700 ">
                        Join our forum of developers, designers and entrepreneurs and find your next friend or job.
                        </p>
                        <ul className="text-gray-600 grid grid-cols-2 gap-4">
                            <li className="flex items-center gap-x-3">
                                <span className="bg-gray-100 text-black  p-0.5 rounded">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                        <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                                    </svg>
                                </span>
                                Networking Opportunities
                            </li>
                            <li className="flex items-center gap-x-3">
                                <span className="bg-gray-100 text-black  p-0.5 rounded">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                        <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                                    </svg>
                                </span>
                                Job Board Access
                            </li>
                            <li className="flex items-center gap-x-3">
                                <span className="bg-gray-100 text-black  p-0.5 rounded">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                        <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                                    </svg>
                                </span>
                                Collaboration Hub
                            </li>
                            <li className="flex items-center gap-x-3">
                                <span className="bg-gray-100 text-black  p-0.5 rounded">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                        <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                                    </svg>
                                </span>
                                Skill Sharing
                            </li>
                            <li className="flex items-center gap-x-3">
                                <span className="bg-gray-100 text-black  p-0.5 rounded">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                        <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                                    </svg>
                                </span>
                                Q&A Support
                            </li>
                            <li className="flex items-center gap-x-3">
                                <span className="bg-gray-100 text-black  p-0.5 rounded">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                        <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                                    </svg>
                                </span>
                                Exclusive Resources
                            </li>
                        </ul>
                        <div className="flex items-center">
                            <button to="#" className="h-12 w-full px-5 rounded-lg bg-black text-white flex  items-center justify-between">
                                <span>
                                    Signup Now
                                </span>
                                <ArrowRight className="size-4"/>
                            </button>
                        </div>
                    </div>
                    <div className="flex-1 relative w-full aspect-[4/2] md:aspect-auto pt-4 px-4 bg-gradient-to-br from-pink-200 to-blue-100 overflow-hidden rounded-t-3xl">
                        <img loading={'lazy'} src="images/forum.webp" alt="cover image" width={1800} className="w-full rounded-xl h-auto" />
                    </div>
                </div>
            </div>
        </section>
    )
    }
    export default CtaSection
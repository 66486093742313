import { Link } from "react-router-dom";

const iconRender = (val) => {
    switch (val) {
        case "cross":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                    <path fillRule="evenodd" d="M2 3a1 1 0 00-1 1v1a1 1 0 001 1h16a1 1 0 001-1V4a1 1 0 00-1-1H2zm0 4.5h16l-.811 7.71a2 2 0 01-1.99 1.79H4.802a2 2 0 01-1.99-1.79L2 7.5zM10 9a.75.75 0 01.75.75v2.546l.943-1.048a.75.75 0 111.114 1.004l-2.25 2.5a.75.75 0 01-1.114 0l-2.25-2.5a.75.75 0 111.114-1.004l.943 1.048V9.75A.75.75 0 0110 9z" clipRule="evenodd" />
                </svg>
            )
        case "sync":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                    <path fillRule="evenodd" d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z" clipRule="evenodd" />
                </svg>
            )
        default:
            return <>No Icon</>;
    }
}

const features = [
    {
        id: 1,
        title: "Custom Application Development",
        icon: "cross",
        description: "We create custom mobile applications that adapt to the specific needs of your business, guaranteeing optimal functionality and performance.",
        advantages: [
            {
                id: 1,
                text: "Custom designs specifically for your needs."
            },
            {
                id: 2,
                text: "Flexibility to grow with your business"
            },
            {
                id: 3,
                text: "Optimization of processes and productivity."
            },
        ],
        image: "images/mobile.webp"
    },
    {
        id: 2,
        title: "Innovative Web Design",
        icon: "cross",
        description: "We design attractive and functional websites that stand out for their creativity and usability, improving the user experience and online presence.",
        advantages: [
            {
                id: 1,
                text: "Attractive and intuitive interfaces."
            },
            {
                id: 2,
                text: "Adapts to any device."
            },
            {
                id: 3,
                text: "Improved visibility in search engines."
            },
        ],
        image: "images/web.webp"
    },
    {
        id: 3,
        title: "Efficient Software Solutions",
        icon: "sync",
        description: "We develop customized software solutions that optimize processes and increase productivity, offering robust and easy-to-use tools.",
        advantages: [
            {
                id: 1,
                text: "Reduction of manual work."
            },
            {
                id: 2,
                text: "Compatibility with existing systems."
            },
            {
                id: 3,
                text: "Data for informed decision-making."
            },
        ],
        image: "/images/app.webp"
    },
    {
        id: 4,
        title: "Custom 3D Design for Unique Projects",
        icon: "cross",
        description: "We create custom 3D models and visualizations that bring your ideas to life, from detailed concepts to realistic prototypes, to stand out in any project.",
        advantages: [
            {
                id: 1,
                text: "Detailed and precise models."
            },
            {
                id: 2,
                text: "Adaptation to specific requirements."
            },
            {
                id: 3,
                text: "Effective presentation of ideas and concepts."
            },
        ],
        image: "images/design.webp"
    },
]

const FeatureItem = ({ id, title, description, advantages, icon, image }) => {
    return (
        <div className={`flex flex-col md:items-center gap-10 lg:gap-14 ${id % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"}`}>
            <div className="md:w-[48%] xl:w-[45%] md:py-6 xl:py-12 space-y-8">
                <div className="space-y-6">
                    <div className="flex flex-row items-center gap-3">
                        <span className="p-2 rounded-md bg-gray-100 dark:bg-gray-600 dark:text-gray-200 text-black flex w-max">
                            {iconRender(icon)}
                        </span>
                        <h2 className="text-2xl font-semibold text-gray-900 dark:text-blue-400">
                            {title}
                        </h2>
                    </div>
                    <p className="text-gray-700 dark:text-gray-200">
                        {description}
                    </p>
                </div>
                <ul role="list" className="space-y-5 children:flex children:items-start children:gap-4 children:text-gray-600 ">
                    {
                        advantages.map(advantage => (
                            <li key={advantage.id} className="flex flex-row items-center gap-3">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 fill-black ">
                                    <path fillRule="evenodd" d="M16.403 12.652a3 3 0 000-5.304 3 3 0 00-3.75-3.751 3 3 0 00-5.305 0 3 3 0 00-3.751 3.75 3 3 0 000 5.305 3 3 0 003.75 3.751 3 3 0 005.305 0 3 3 0 003.751-3.75zm-2.546-4.46a.75.75 0 00-1.214-.883l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
                                </svg>
                                {advantage.text}
                            </li>
                        ))
                    }
                </ul>
            </div>
            <div className="flex-1 mt-5 relative bg-gradient-to-tr from-sky-100 to-indigo-300 dark:from-gray-700 dark:to-gray-900 p-6 rounded-3xl aspect-[4/2.4] overflow-hidden">
                <img
                    src={image}
                    alt="illustration"
                    width={1800}
                    height={0}
                    className="w-full h-auto rounded-lg"
                />
            </div>

        </div>
    )
}

const Features = () => {
    return (
        <section className="py-16">
            <div className="max-w-7xl mx-auto px-5 sm:px-10 md:px-12 lg:px-5">
                <div className="flex flex-col  space-y-16">
                    <div className="flex flex-col justify-center text-center  mx-auto md:max-w-2xl space-y-5">
                        <span className="rounded-lg bg-gray-100  px-2.5 py-1 text-xs w-max mx-auto font-semibold tracking-wide text-black ">Features</span>
                        <h1 className="text-3xl font-semibold text-blue-950 dark:text-blue-400  md:text-4xl xl:text-5xl leading-tight">
                            Innovative Solutions for Digital Development
                        </h1>
                        <p className="text-gray-700 dark:text-gray-200  max-w-lg mx-auto">
                            Specialized in web development, mobile applications and custom software, we offer innovative solutions that adapt to the needs of your business to maximize its potential.
                        </p>
                    </div>
                    <div className="grid divide-y divide-gray-300/60  gap-12 children:py-5 first:pt-0 last:pb-0">
                        {
                            features.map(feature => (
                                <FeatureItem key={feature.id} {...feature} />
                            ))
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Features
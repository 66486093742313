import {
    Smartphone,
    Activity,
    MessageCircle,
    Heart,
    Database,
    ShoppingCart,
} from 'lucide-react'
import { useState } from 'react';
import CallBack from '../components/CallBack';
import ScrollToTop from '../components/ScrollToTop';

export default function App() {

    const [openFAQ, setOpenFAQ] = useState(null);

    // Function to toggle the visibility of FAQ details
    const toggleFAQ = (index) => {
        setOpenFAQ(openFAQ === index ? null : index);
    };

    // Dummy data for FAQs
    const faqs = [
        {
            title: "How long does it take to develop a mobile app?",
            content:
                "Developing an app depends on the complexity of the project, the desired features, and the platform. Typically, a fully functional application can take anywhere from 4 weeks to 6 months, including testing and deployment.",
        },
        {
            title: "What is the cost of developing an app?",
            content:
                " The cost varies depending on the features, design, platform (iOS, Android, or both), and customization needed. We can provide an estimate based on your specific requirements.",
        },
        {
            title: "Will I be able to update the app after it’s launched?",
            content:
                "Yes, we offer ongoing support and maintenance services, allowing you to update and enhance your app as your business evolves and new technologies emerge.",
        },
        {
            title: "Can my app be integrated with other platforms and services?",
            content:
                "Absolutely. We can integrate your app with third-party services, APIs, and platforms such as payment gateways, social media, and analytics tools to create a seamless user experience.",
        },
    ];

    const feacture = [
        {
            id: 1,
            title: 'Greater visibility and accessibility',
            description: "An app puts you directly in the customer's pocket, facilitating access to your services or products from anywhere.",
            icon: Smartphone
        },
        {
            id: 2,
            title: 'Optimized user experience',
            description: 'Apps offer faster and smoother navigation compared to websites, improving user interaction.',
            icon: Activity
        },
        {
            "id": 3,
            "title": "Direct Communication Channel",
            "description": "Push notifications allow you to communicate directly with users more effectively than emails or ads.",
            "icon": MessageCircle
        },
        {
            "id": 4,
            "title": "Increased Customer Loyalty",
            "description": "An app allows the implementation of loyalty and reward programs, which increases customer retention and engagement.",
            "icon": Heart
        },
        {
            "id": 5,
            "title": "Data Collection for Personalization",
            "description": "Apps can collect usage data, enabling you to personalize offers and improve decision-making based on customer behavior.",
            "icon": Database
        },
        {
            "id": 6,
            "title": "Improved Sales and Revenue Generation",
            "description": "Apps simplify purchases and transactions, increasing conversion and offering direct monetization options such as in-app purchases.",
            "icon": ShoppingCart
        }
    ]


    return (
        <main>
            <ScrollToTop/>
            <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-20">
                {/* Component */}
                <div className="grid grid-cols-1 items-center gap-8 sm:gap-20 lg:grid-cols-2">
                    {/* Heading Div */}
                    <div className="max-w-3xl">
                        <h1 className="mb-6 pb-4 text-4xl font-bold md:text-6xl">
                            Why develop an App for your business?
                        </h1>
                        <p className="mb-6 max-w-lg text-gray-500 md:mb-10 lg:mb-12">
                            In today's digital age, developing a mobile app can make a significant difference in the success and growth of your business. Here we explain the main reasons why you should consider creating an app:
                        </p>
                        <a
                        href={'/app#call-us'}
                            className="mb-5 mr-5 inline-block rounded-lg bg-black px-6 py-4 text-center font-bold text-white transitionss lg:mb-8 lg:mr-8"
                        >
                            Call Me
                        </a>
                    </div>
                    {/* Image Div */}
                    <div>
                        <img
                            src="images/application.webp"
                            alt=""
                            className="mx-auto inline-block rounded-3xl h-full w-full max-w-2xl object-cover"
                        />
                    </div>
                </div>
            </div>
            <section>
                {/* Container */}
                <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-20">
                    {/* Title */}
                    <div className="flex flex-col items-center text-center">
                        <h2 className="text-3xl font-bold md:text-5xl">
                            Key Benefits of Developing a Mobile Application
                        </h2>
                        <p className="mb-8 mt-4 max-w-xl text-base text-gray-500 md:mb-12 md:text-lg lg:mb-16">
                            Developing a mobile application provides businesses with a direct channel to engage users, enhance customer experiences, and drive growth through tailored solutions and increased accessibility.
                        </p>
                    </div>
                    {/* Features Content */}
                    <div className="grid gap-5 sm:grid-cols-2 md:grid-cols-3 md:gap-4 lg:gap-6">
                        {/* Features Item */}
                        {
                            feacture.map(feactures => {
                                const IconComponent = feactures.icon;
                                return (
                                    <div key={feactures.id} className="grid gap-6 rounded-3xl border border-solid border-gray-300 / 80 p-8 md:p-10">
                                        <div className="w-16 h-16 bg-gray-100 rounded-full flex justify-center items-center">
                                            <IconComponent className="w-8 h-8 text-black" />
                                        </div>
                                        <h3 className="text-xl font-semibold">{feactures.title}</h3>
                                        <p className="text-sm text-gray-500">
                                            {feactures.description}
                                        </p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            <section>
                <div className="py-16 md:py-20 mx-auto w-full max-w-7xl px-5 md:px-10">
                    <div className="flex flex-col items-start lg:flex-row lg:space-x-20">
                        <div className="lg:flex-[1_1_500px] w-full flex-none">
                            <div className="max-w-3xl mb-8 md:mb-12 lg:mb-16">
                                <h2 className="font-bold text-3xl md:text-5xl">General FAQs</h2>
                                <div className="mt-4 max-w-lg">
                                    <p className="text-gray-500 text-sm sm:text-base">
                                        Below are some of the most frequently asked questions by our clients, along with their answers, to help you better understand the app development process
                                    </p>
                                </div>
                            </div>
                            <div className="mb-6 h-full w-full overflow-auto bg-gray-100 p-8 rounded-3xl">
                                <div className="flex flex-row gap-4">
                                    <img
                                        src="images/user.png"
                                        alt="Placeholder"
                                        className="inline-block h-12 w-12 object-cover rounded-full"
                                    />
                                    <div className="flex flex-col gap-1.5">
                                        <h5 className="text-xl font-bold">Still have questions?</h5>
                                        <div className="max-w-sm">
                                            <p className="text-gray-500 text-sm sm:text-base">
                                                Can’t find the answer you’re looking for? Please chat to
                                                our support.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-6 mt-8 h-[0.5px] w-full bg-gray-300"></div>
                                <a
                                    href={'/app#call-us'}
                                    className="inline-block items-center rounded-md bg-black px-6 py-3 text-center font-semibold text-white"
                                >
                                    Call me
                                </a>
                            </div>
                        </div>
                        <div className="lg:flex-[1_1_500px] w-full flex-none">
                            {faqs.map((faq, index) => (
                                <div
                                    key={index}
                                    className="mb-6 w-full overflow-hidden bg-gray-100 p-8 rounded-3xl"
                                >
                                    <div
                                        className="flex cursor-pointer items-start justify-between"
                                        onClick={() => toggleFAQ(index)}
                                    >
                                        <p className="text-lg font-semibold">{faq.title}</p>
                                        <div className="relative ml-10 mt-1 flex h-5 w-5 items-center justify-center">
                                            <div
                                                className={`absolute h-5 w-0.5 bg-black transition-transform duration-300 ${openFAQ === index ? "rotate-90" : ""}`}
                                            ></div>

                                            <div className="h-0.5 w-5 bg-black"></div>
                                        </div>
                                    </div>
                                    {openFAQ === index && (
                                        <div className="w-full overflow-hidden mb-4 max-w-2xl lg:max-w-4xl">
                                            <p className="text-sm sm:text-base">{faq.content}</p>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <CallBack/>
        </main>
    )
}
import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements, PaymentRequestButtonElement } from '@stripe/react-stripe-js';

// Load your Stripe public key
const stripePromise = loadStripe("pk_test_51QAdA4L8wxrBYan1ekw3a5am47VwsNYdvIPD19vMYip8m6LMOc5Rb1DmAt69dVQDDNmGQy7uhTnOKutdqUCKTAlQ00suGwtLWi");

const CheckoutForm = ({ totalAmount }) => {
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [isPaymentRequestReady, setIsPaymentRequestReady] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!stripe) return;

    // Create a PaymentRequest object when Stripe is available
    const request = stripe.paymentRequest({
      country: "US", // Set to your country
      currency: "usd", // Set to your currency
      total: {
        label: "Total",
        amount: totalAmount * 100, // Stripe expects the amount in cents
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    // Check if the PaymentRequest can be supported
    request.canMakePayment().then((result) => {
      if (result?.googlePay) {
        setIsPaymentRequestReady(true);  // Show the Google Pay button
        setPaymentRequest(request);
      } else {
        setIsPaymentRequestReady(false);  // Hide the button if Google Pay isn't supported
      }
    });

    // Cleanup paymentRequest when component unmounts
    return () => {
      setPaymentRequest(null);
    };
  }, [stripe, totalAmount]);

  const [data, setData] = useState({
    name: "",
    email: "",
    id: "",
    total: totalAmount.toFixed(2),
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    // Make API call to backend to create the payment intent with the totalAmount
    const response = await fetch("http://localhost:3000/create-payment-intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ amount: totalAmount }),
    });

    const { clientSecret } = await response.json();

    // Confirm payment with Stripe
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    

    if (result.error) {
      setPaymentStatus(result.error.message);
      setLoading(false);
    } else {
      if (result.paymentIntent.status === "succeeded") {
        setPaymentStatus("Pago exitoso!");
        const paymentIntentId = result.paymentIntent.id
        setData({ ...data, id: paymentIntentId });

        try {
          const response = await fetch("http://localhost:3000/payment", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          });

          const result = await response.json();
          if (response.ok) {
            setData({
              name: "",
              email: "",
              id: "",
              total: totalAmount.toFixed(2),
            });
            localStorage.setItem("cart", [{}]);
          } else {
            alert(`Error: ${result.error}`);
          }
        } catch (error) {
          console.error("Error:", error);
          alert("Hubo un error al enviar el formulario.");
        }

        setLoading(false);
      }
    }
  };

  // Handle Google Pay or other payment method
  const handleGooglePaySubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !paymentRequest) {
      return;
    }

    const { error, paymentIntent } = await paymentRequest.confirmPayment();

    if (error) {
      setPaymentStatus(`Error: ${error.message}`);
    } else if (paymentIntent.status === "succeeded") {
      setPaymentStatus("Pago exitoso con Google Pay!");
    }
  };

  return (
    <div className="flex justify-center items-center ">
      <div className="bg-white w-full">

        {/* Show Google Pay button if it's ready */}
        {isPaymentRequestReady && paymentRequest && (
          <div className="mb-6">
            <PaymentRequestButtonElement
              paymentRequest={paymentRequest}
              className="w-full"
              onClick={handleGooglePaySubmit}
            />
          </div>
        )}

        {/* Regular card form for other payment methods */}
        {!isPaymentRequestReady && (
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="">
              <label htmlFor="card" className="text-lg font-semibold">Card Information</label>
              <div>
                <small>
                  This Information is required for emit your bill.
                </small>
              </div>
              <input
                type="text"
                placeholder="Full name"
                className="w-full p-4 mt-6 border my-2 border-gray-200 focus:border-gray-200 rounded-lg focus:outline-none focus:ring-0"
                onChange={(e) => setData({ ...data, name: e.target.value })}
                value={data.name}
              />

              <input type="text" placeholder='Email address' className='w-full p-4 border my-2 border-gray-200 focus:border-gray-200 rounded-lg focus:outline-none focus:ring-0 mb-4' onChange={(e) => setData({ ...data, email: e.target.value })}
                value={data.email} />
              <div className="border p-3 rounded-lg py-4">
                <CardElement id="card" options={{
                  style: {
                    base: {
                      color: "#000",
                      fontSize: "16px",
                      fontFamily: "Arial, sans-serif",
                      fontSmoothing: "antialiased",
                      "::placeholder": {
                        color: "#aab7c4"
                      }
                    }
                  }
                }} />
              </div>
            </div>

            <div className="flex justify-between items-center">
              <button
                type="submit"
                disabled={!stripe}
                className="bg-gray-900 text-white w-full p-4 py-4 rounded-lg hover:bg-gray-800 transition duration-200"
              >
                {
                  loading ? <>
                    <div className="animate-spin text-lg font-medium inline-block size-4 border-[1px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading">
                      <span className="sr-only">Loading...</span>
                    </div></> : <>Pay <span className="">{totalAmount.toFixed(2)} USD</span></>
                }
              </button>
            </div>
            {paymentStatus && <p>{paymentStatus}</p>}
          </form>
        )}
      </div>
    </div>
  );
};

const StripeCheckout = ({ totalAmount }) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm totalAmount={totalAmount} />
    </Elements>
  );
};

export default StripeCheckout;

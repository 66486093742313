import React, { useState } from 'react';

const testimonials = [
    // English
    { id: 1, name: 'John Smith', role: 'User Not Registered', description: 'The service was exceptional, exceeding our expectations with a high-quality result.', image: "images/user.webp" },

    // Spanish
    { id: 2, name: 'Carlos Mendoza', role: 'User Not Registered', description: 'El servicio fue excepcional, superaron nuestras expectativas con un resultado de alta calidad.', image: "images/user.webp" },

    // French
    { id: 3, name: 'Jean Dupont', role: 'User Not Registered', description: 'Le service était exceptionnel, dépassant nos attentes avec un résultat de haute qualité.', image: "images/user.webp" },

    // Norwegian
    { id: 4, name: 'Erik Hansen', role: 'User Not Registered', description: 'Tjenesten var eksepsjonell, overgikk våre forventninger med et resultat av høy kvalitet.', image: "images/user.webp" },

    // Swedish
    { id: 5, name: 'Anna Svensson', role: 'User Not Registered', description: 'Servicen var exceptionell och överträffade våra förväntningar med ett resultat av hög kvalitet.', image: "images/user.webp" },

    // Danish
    { id: 6, name: 'Kirsten Andersen', role: 'User Not Registered', description: 'Servicen var enestående og overgik vores forventninger med et resultat af høj kvalitet.', image: "images/user.webp" },

    // English
    { id: 7, name: 'Emily Johnson', role: 'User Not Registered', description: 'A highly professional and efficient team. The project was delivered on time with excellent results.', image: "images/user.webp" },

    // Spanish
    { id: 8, name: 'Ana López', role: 'User Not Registered', description: 'Un equipo muy profesional y eficiente. El proyecto se entregó a tiempo y con excelentes resultados.', image: "images/user.webp" },

    // French
    { id: 9, name: 'Marie Dubois', role: 'User Not Registered', description: 'Une équipe très professionnelle et efficace. Le projet a été livré à temps avec d’excellents résultats.', image: "images/user.webp" },

    // Norwegian
    { id: 10, name: 'Ingrid Olsen', role: 'User Not Registered', description: 'Et svært profesjonelt og effektivt team. Prosjektet ble levert til tiden med utmerkede resultater.', image: "images/user.webp" },

    // Swedish
    { id: 11, name: 'Lars Johansson', role: 'User Not Registered', description: 'Ett mycket professionellt och effektivt team. Projektet levererades i tid med utmärkta resultat.', image: "images/user.webp" },

    // Danish
    { id: 12, name: 'Peter Jensen', role: 'User Not Registered', description: 'Et yderst professionelt og effektivt team. Projektet blev leveret til tiden med fremragende resultater.', image: "images/user.webp" },

    // English
    { id: 13, name: 'William Brown', role: 'User Not Registered', description: 'The project was completed ahead of schedule, and the quality was top-notch. Excellent work!', image: "images/user.webp" },

    // Spanish
    { id: 14, name: 'Luis García', role: 'User Not Registered', description: 'Estoy muy satisfecho con el trabajo. La atención al detalle y la solución de problemas fueron sobresalientes.', image: "images/user.webp" },

    // French
    { id: 15, name: 'Paul Martin', role: 'User Not Registered', description: 'Le projet a été achevé avant le délai prévu, et la qualité était excellente. Travail remarquable !', image: "images/user.webp" },

    // Norwegian
    { id: 16, name: 'Sofie Larsen', role: 'User Not Registered', description: 'Prosjektet ble fullført før tidsfristen, og kvaliteten var utmerket. Fantastisk arbeid!', image: "images/user.webp" },

    // Swedish
    { id: 17, name: 'Erik Andersson', role: 'User Not Registered', description: 'Projektet slutfördes före tidsfristen och kvaliteten var utmärkt. Fantastiskt arbete!', image: "images/user.webp" },

    // Danish
    { id: 18, name: 'Mette Sørensen', role: 'User Not Registered', description: 'Projektet blev færdigt før tidsfristen, og kvaliteten var fremragende. Fantastisk arbejde!', image: "images/user.webp" },

    // English
    { id: 19, name: 'Sophia Davis', role: 'User Not Registered', description: 'The results were beyond our expectations. The team was responsive and highly skilled.', image: "images/user.webp" },

    // Spanish
    { id: 20, name: 'María Fernández', role: 'User Not Registered', description: 'El proceso fue muy fluido y el resultado final superó nuestras expectativas. ¡Altamente recomendados!', image: "images/user.webp" },

    // French
    { id: 21, name: 'Claire Dupuis', role: 'User Not Registered', description: 'Les résultats ont dépassé nos attentes. L’équipe était réactive et très compétente.', image: "images/user.webp" },

    // Norwegian
    { id: 22, name: 'Anders Bjørnsen', role: 'User Not Registered', description: 'Resultatene var over våre forventninger. Teamet var responsivt og svært kompetent.', image: "images/user.webp" },

    // Swedish
    { id: 23, name: 'Frida Larsson', role: 'User Not Registered', description: 'Resultaten var över våra förväntningar. Teamet var responsivt och mycket kompetent.', image: "images/user.webp" },

    // Danish
    { id: 24, name: 'Lars Madsen', role: 'User Not Registered', description: 'Resultaterne var ud over vores forventninger. Teamet var responsivt og meget kompetent.', image: "images/user.webp" },

    // English
    { id: 25, name: 'James Wilson', role: 'User Not Registered', description: 'The team demonstrated a high level of professionalism and commitment. We are very happy with the outcome.', image: "images/user.webp" },

    // Spanish
    { id: 26, name: 'José Martínez', role: 'User Not Registered', description: 'El equipo mostró un gran nivel de profesionalismo y compromiso. Estamos muy contentos con el resultado.', image: "images/user.webp" },

    // French
    { id: 27, name: 'Jacques Bernard', role: 'User Not Registered', description: 'L’équipe a démontré un haut niveau de professionnalisme et d’engagement. Nous sommes très satisfaits du résultat.', image: "images/user.webp" },

    // Norwegian
    { id: 28, name: 'Kari Nilsen', role: 'User Not Registered', description: 'Teamet viste et høyt nivå av profesjonalitet og engasjement. Vi er veldig fornøyde med resultatet.', image: "images/user.webp" },

    // Swedish
    { id: 29, name: 'Mikael Eriksson', role: 'User Not Registered', description: 'Teamet visade en hög nivå av professionalism och engagemang. Vi är mycket nöjda med resultatet.', image: "images/user.webp" },

    // Danish
    { id: 30, name: 'Sanne Christensen', role: 'User Not Registered', description: 'Teamet viste et højt niveau af professionalisme og engagement. Vi er meget tilfredse med resultatet.', image: "images/user.webp" },

    // English
    { id: 31, name: 'Olivia Brown', role: 'User Not Registered', description: 'An outstanding experience from start to finish. Their attention to detail and creativity were impressive.', image: "images/user.webp" },

    // Spanish
    { id: 32, name: 'Claudia Rodríguez', role: 'User Not Registered', description: 'Un trabajo excelente desde el principio hasta el final. La atención al detalle y la creatividad fueron impresionantes.', image: "images/user.webp" },

    // French
    { id: 33, name: 'Éric Lefèvre', role: 'User Not Registered', description: 'Une expérience exceptionnelle du début à la fin. Leur attention aux détails et leur créativité étaient impressionnantes.', image: "images/user.webp" },

    // Norwegian
    { id: 34, name: 'Bente Thomsen', role: 'User Not Registered', description: 'En enestående opplevelse fra start til slutt. Deres oppmerksomhet på detaljer og kreativitet var imponerende.', image: "images/user.webp" },

    // Swedish
    { id: 35, name: 'Oskar Svensson', role: 'User Not Registered', description: 'En enastående upplevelse från början till slut. Deras uppmärksamhet på detaljer och kreativitet var imponerande.', image: "images/user.webp" },

    // Danish
    { id: 36, name: 'Line Petersen', role: 'User Not Registered', description: 'En fremragende oplevelse fra start til slut. Deres opmærksomhed på detaljer og kreativitet var imponerende.', image: "images/user.webp" },

    // English
    { id: 37, name: 'Lucas Johnson', role: 'User Not Registered', description: 'The team exceeded our expectations in every way. The results were delivered on time and were of the highest quality.', image: "images/user.webp" },

    // Spanish
    { id: 38, name: 'Isabella Sánchez', role: 'User Not Registered', description: 'El equipo superó nuestras expectativas en todos los aspectos. Los resultados se entregaron a tiempo y eran de la más alta calidad.', image: "images/user.webp" },

    // French
    { id: 39, name: 'Hélène Roy', role: 'User Not Registered', description: 'L’équipe a dépassé nos attentes à tous égards. Les résultats ont été livrés à temps et étaient de la plus haute qualité.', image: "images/user.webp" },

    // Norwegian
    { id: 40, name: 'Johan Jensen', role: 'User Not Registered', description: 'Teamet overgikk våre forventninger på alle måter. Resultatene ble levert i tide og var av høyeste kvalitet.', image: "images/user.webp" },

    // Swedish
    { id: 41, name: 'Linnea Nilsson', role: 'User Not Registered', description: 'Teamet överträffade våra förväntningar på alla sätt. Resultaten levererades i tid och var av högsta kvalitet.', image: "images/user.webp" },

    // Danish
    { id: 42, name: 'Anders Nielsen', role: 'User Not Registered', description: 'Teamet overgik vores forventninger på alle måder. Resultaterne blev leveret til tiden og var af højeste kvalitet.', image: "images/user.webp" },
];



const TestimonialSection = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
        );
    };

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
        );
    };

    // Calculate the range of dots to display
    const startIndex = Math.max(currentIndex - 2, 0);
    const endIndex = Math.min(currentIndex + 3, testimonials.length);


    return (
        <section className="py-20">
            <div className="max-w-7xl mx-auto px-5 sm:px-10 md:px-12 lg:px-5 space-y-16">
                <div className="space-y-4 max-w-2xl">
                    <span className="rounded-lg bg-gray-100 px-2.5 py-1 text-xs font-semibold tracking-wide text-black">
                        Testimonials
                    </span>
                    <h1 className="text-3xl font-bold text-gray-900">
                        What They Say About Us
                    </h1>
                </div>
                <div className="relative grid md:grid-cols-3 lg:grid-cols-2 md:gap-6">
                    <div className="absolute -left-10 md:left-0 inset-y-0 w-3/5 z-0">
                        <img
                            loading={'lazy'}
                            src="images/creative-agency-production.webp"
                            alt="Creative Agency Production"
                            width={1800}
                            height={1800}
                            className="w-full h-full object-cover rounded-2xl"
                        />
                    </div>
                    <div className="flex col-span-1" />
                    <div className="w-full col-span-1 overflow-hidden md:col-span-2 lg:col-span-1 relative flex flex-col gap-8 z-[5] py-10 md:py-14">
                        <div key={testimonials[currentIndex].id} className="h-auto rounded-2xl bg-white border border-gray-200/50">
                            <div className="flex gap-5 md:gap-6">
                                <div className="flex min-w-max">
                                    <img
                                        loading={'lazy'}
                                        src={testimonials[currentIndex].image}
                                        alt={`${testimonials[currentIndex].name}'s Avatar`}
                                        width={500}
                                        height={500}
                                        className="w-20 h-20 mt-3 ml-3 sm:w-28 sm:h-28 md:w-32 md:h-32 object-cover rounded-2xl"
                                    />
                                </div>
                                <div className="p-4 sm:p-6 md:p-8 lg:p-10 h-full flex flex-col gap-5">
                                    <div className="flex flex-col">
                                        <span className="text-xl md:text-2xl font-semibold text-gray-800">
                                            {testimonials[currentIndex].name}
                                        </span>
                                        <small className="text-gray-600">{testimonials[currentIndex].role}</small>
                                    </div>
                                    <div>
                                        <p className="line-clamp-5 text-gray-700">
                                            {testimonials[currentIndex].description}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex md:justify-end items-center">
                            <div class="trustpilot-widget" data-locale="en-US" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="67168e8f6b204f795d89ee52" data-style-height="52px" data-style-width="100%">
                                <a href="https://www.trustpilot.com/review/ark-platforms.com" target="_blank" rel="noopener">Trustpilot</a>
                            </div>
                            <div className="flex items-center gap-4 w-max p-1.5 rounded-full bg-white shadow-lg shadow-gray-200/30 border border-gray-200/40">
                                <button
                                    aria-label="Previous"
                                    onClick={handlePrevClick}
                                    className="outline-none text-gray-700 transition hover:text-blue-600 p-2 md:p-2.5 hover:bg-blue-100 rounded-full"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        className="w-5 h-5"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M18 10a.75.75 0 01-.75.75H4.66l2.1 1.95a.75.75 0 11-1.02 1.1l-3.5-3.25a.75.75 0 010-1.1l3.5-3.25a.75.75 0 111.02 1.1l-2.1 1.95h12.59A.75.75 0 0118 10z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                                <button
                                    aria-label="Next"
                                    onClick={handleNextClick}
                                    className="outline-none text-gray-700 transition hover:text-blue-600 p-2 md:p-2.5 hover:bg-blue-100 rounded-full"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        className="w-5 h-5"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TestimonialSection;

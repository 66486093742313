// App.js
import "preline/preline";
import DefaultLayout from "./layout/Layout";
import AppRoutes from "./routes/routes";
import { ThemeProvider } from "./components/ThemeContext";
import './App.css';
import { Route } from "react-router-dom";
import CartPage from "./pages/CartPage";

function App() {
  return (
    <ThemeProvider>
      <DefaultLayout>
        <AppRoutes />
      </DefaultLayout>
    </ThemeProvider>
  );
}

export default App;

export default function workPrivacy() {

    const workPrivacyPolicy = [
        {
            "id": 1,
            "title": "Introduction",
            "content": "Welcome to Ark Platforms, Inc. This work Services and Contracts Policy explains how we handle and protect information related to source codes and work shared between us and users. By using our services, you agree to the terms outlined in this policy, ensuring a mutual understanding of how your information is managed."
        },
        {
            "id": 2,
            "title": "Sharing of Source Codes",
            "content": "The source codes and other work shared between Ark Platforms, Inc. and the user are treated with the utmost confidentiality. We will not share, sell, or transfer these materials to third parties without the express written consent of the original owner. This policy is designed to protect your intellectual property and ensure that your work remains secure."
        },
        {
            "id": 3,
            "title": "Payment Methods",
            "content": "All work is conducted under a structured payment method consisting of a 50% upfront payment at the start of the project and the remaining 50% due upon completion. This arrangement not only secures our commitment to delivering high-quality work but also aligns our interests with yours, ensuring timely project delivery."
        },
        {
            "id": 4,
            "title": "Budget Validity",
            "content": "Budgets provided by Ark Platforms, Inc. are valid for a period of 14 days from the date of issuance. If the project is not initiated within this time frame, the budget may be subject to revision based on market conditions, resource availability, and any changes in project scope."
        },
        {
            "id": 5,
            "title": "Free Domain Offer",
            "content": "For all web design and development projects, Ark Platforms, Inc. offers a free domain for 15 days after the project delivery. The domain will be controlled and managed by Ark Platforms, Inc. If you wish for the domain to become your property, you will need to pay the corresponding fee established by Ark Platforms, Inc."
        },
        {
            "id": 6,
            "title": "Software and App Signatures",
            "content": "All software developed by Ark Platforms, Inc. will carry an SSH signature indicating its development by us. For applications, the signature will be in the format: com.arkplatform.[application_name]. If you wish for these signatures to be transferred to your ownership, you will need to make the corresponding payment as determined by Ark Platforms, Inc."
        },
        {
            "id": 7,
            "title": "Information Storage and Retention",
            "content": "Source codes, designs, and other materials shared by users with Ark Platforms, Inc. will be stored securely for a period of 3 months. During this time, we will take appropriate measures to protect your data from unauthorized access or breaches. After the retention period, materials will be deleted securely to prevent unauthorized recovery."
        },
        {
            "id": 8,
            "title": "Data Security Measures",
            "content": "We implement a variety of security measures to ensure the safety of your shared information. This includes data encryption, secure servers, and strict access controls. While we strive to maintain the highest levels of security, please be aware that no system is completely infallible."
        },
        {
            "id": 9,
            "title": "User Responsibilities",
            "content": "Users are responsible for ensuring that any materials shared with Ark Platforms, Inc. do not infringe on the rights of third parties. By sharing your work with us, you confirm that you have the necessary rights and permissions to do so, and that the materials do not violate any applicable laws or regulations."
        },
        {
            "id": 10,
            "title": "Changes to This Policy",
            "content": "We may update this work Services and Contracts Policy from time to time to reflect changes in our practices or applicable laws. Any updates will be posted on this page with an updated effective date. Your continued use of our services after such changes signifies your acceptance of the updated policy."
        },
        {
            "id": 11,
            "title": "Contact Us",
            "content": "If you have any questions or concerns regarding this work Services and Contracts Policy or our data practices, please do not hesitate to contact us at info@ark-platforms.com or +47 922 63 901. We are committed to addressing your inquiries and resolving any issues you may have."
        },
        {
            "id": 12,
            "title": "Completion of Work and Payment Obligations",
            "content": "Upon completion and delivery of the work, a temporary KEY will be provided to identify the user in the databases of Ark Platforms, Inc. If payment is not made, this KEY will remain temporary, limiting access to future updates and technical support. If the user or company fails to pay the agreed amount, Ark Platforms, Inc. reserves the right to take appropriate legal action, regardless of the user's or company's location. Ark Platforms, Inc. provides two free updates for improvements, as initial versions of software or applications may still contain minor issues that do not affect functionality. If payment has not been made, the user will be required to pay at least half of the total amount before any additional services or updates can be carried out. In the event of non-compliance, all services related to the project will be suspended, including the cessation of any technical support, maintenance, and access to future functionalities. Failure to comply with payment obligations may lead to legal consequences, and we encourage all users and companies to maintain constant communication with Ark Platforms, Inc. to ensure timely payments and avoid any inconveniences."
        },
        {
            "id": 13,
            "title": "Loss of Communication with Clients",
            "content": "In the event of a loss of communication with the client for an extended period, Ark Platforms, Inc. reserves the right to sell or distribute the systems developed by us on any platform. This action may be taken to recoup costs and mitigate potential losses resulting from the lack of engagement or responsiveness from the client. We encourage all clients to maintain open lines of communication to avoid any disruptions or misunderstandings."
        }
    ];
    


    return (
        <section>
            <div className="flex flex-col items-center justify-center px-5 md:px-10">
                {/* Title Container */}
                <div className="flex h-auto min-w-[90vw] flex-col items-center justify-end bg-gray-100 rounded-b-3xl py-6 md:h-64">
                    <div className="flex flex-col items-center gap-y-4 py-5">
                        <h1 className="text-3xl font-bold md:text-5xl text-center">Services and Contracts Policy</h1>
                        <p className="text-sm text-[#808080] sm:text-base">Last Updated as of Oct 12, 2024</p>
                    </div>
                </div>
                {/* Content Container */}
                <div className="mx-auto w-full max-w-5xl py-12 md:py-16 lg:py-20">
                    {/* Component */}
                    <div className="flex flex-col items-center gap-y-14">
                        <p className="max-w-full text-start text-sm sm:text-base">Our Services and Contracts Policy outlines how Ark Platforms, Inc. manages contracts and service agreements with clients. This policy describes the types of information we collect during the contracting process, the purposes for which we use this information, and the measures we implement to ensure the integrity and security of these contracts. We are committed to fostering a transparent relationship with our clients regarding our contractual practices. By engaging with our services, you agree to the terms outlined in this policy. If you have any questions or concerns, please refer to the Contact Us section of our policy.

</p>
                        <div className="flex min-w-full flex-col gap-y-10">
                            <div className="flex min-w-full py-4 [border-bottom:1px_solid_rgb(226,_226,_226)]">
                                <h6 className="text-base font-bold uppercase">Services and Contracts Policy</h6>
                            </div>
                            <div className="flex flex-col gap-y-10">
                                <div className="flex min-w-full flex-col items-start gap-y-6">
                                    {workPrivacyPolicy.map(term => (
                                        <div key={term.id} className="flex flex-col items-start gap-y-3">
                                            <p className="text-xl font-semibold">{term.title}</p>
                                            <p className="text-sm">{term.content}</p>
                                        </div>
                                    ))}
                                </div>
                                <div className="min-h-[1px] min-w-full bg-[#e2e2e2]"></div>
                                <p className="text-sm">By accessing, browsing, or utilizing any design services, communication channels, or materials provided by Ark Platforms, Inc, including but not limited to graphic design, web design, branding, illustration, and user interface design, whether through our website, email, phone, or any other means, you expressly acknowledge, understand, and agree that you have carefully read, comprehended, and fully consent to be legally bound by all the provisions, terms, and conditions set forth in these Terms of Service, including any additional agreements, policies, guidelines, or amendments referenced or incorporated herein.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}
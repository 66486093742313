import { doc, setDoc } from 'firebase/firestore';
import {
    ArrowRight,
    Headset
} from 'lucide-react'
import { useState } from 'react';
import { db } from '../db/FirebaseConfig';

export default function CallBack() {

    const [selectedDate, setSelectedDate] = useState("");
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [sendMessage, setSend] = useState(null)

    // Obtener la fecha actual en formato YYYY-MM-DD
    const today = new Date().toISOString().split("T")[0];

    const handleChange = (e) => {
        setSelectedDate(e.target.value);
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options);
    };

    const requestCall = async (e) => {
        e.preventDefault();
        const pathname = window.location.pathname;
        const lastSegment = pathname.split('/').filter(Boolean).pop();
        const randomString = Math.random().toString(36).substr(2, 8).toUpperCase();
        const newIdentifier = `${randomString}`;
        await setDoc(doc(db, "requires", newIdentifier), {
            name: name,
            phone: phone,
            day: selectedDate,
            type: lastSegment
        });
        setSend(true);
    };


    return (
        <section id='call-us'>
            {/* Container */}
            <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-20">
                {/* Component */}
                <div className="grid items-start gap-8 sm:gap-20 lg:grid-cols-2 ">
                    <div className="max-w-3xl">
                        {/* Title */}
                        <h2 className="mb-2 text-3xl font-bold md:text-5xl">
                            Schedule a Professional Consultation
                        </h2>
                        <p className="my-4 max-w-lg pb-4 text-sm text-gray-500 sm:text-base md:mb-6 lg:mb-8">
                            Did you know that a personalized consultation can help you better understand how a custom app can transform your business? Fill out the form, and our team will reach out to guide you through the process.
                        </p>
                    </div>
                    <div className="mx-auto max-w-xl bg-gray-100 p-8 text-start rounded-3xl">
                        <h3 className="text-2xl font-bold md:text-3xl">Request a Callback</h3>
                        <p className="mx-auto mb-6 mt-4 max-w-lg text-sm text-gray-500 lg:mb-8">
                            Submit your details, and we'll contact you shortly to discuss your project.
                        </p>
                        {/* Form */}
                        {
                            sendMessage ?
                                <>
                                    <div className='w-full h-auto bg-gray-200 p-4 rounded-xl'>
                                        <Headset size={50} color='#000'/>
                                        <h1 className='font-semibold text-2xl my-3'>
                                            Scheduled call
                                        </h1>
                                        <p>
                                        Hi, <b>{name}</b>! Your call has been scheduled for the day <b>{formatDate(selectedDate)}</b>, so please wait for the call from our agents. Thank you so much! Have a great day. <br /><br />
                                        <b>Ark Team</b>
                                        </p>
                                    </div>
                                </>
                                :
                                <>
                                    <form
                                        className=" mb-4 text-start"
                                        name="wf-form-password"
                                        onSubmit={requestCall}
                                    >
                                        <div>
                                            <label htmlFor="name-2" className="mb-1 font-medium text-indigo-950">
                                                Your Name
                                            </label>
                                            <input
                                                type="text"
                                                onChange={(e) => setName(e.target.value)}
                                                value={name.toUpperCase()}
                                                className="mb-4 block h-9 w-full rounded-md border border-solid mt-2 border-gray-300 /80 px-3 py-6 pl-4 text-sm text-black focus:outline-none focus:right-0"
                                            />
                                        </div>
                                        <div className="mb-6">
                                            <label htmlFor="name-2" className="mb-1 font-medium text-indigo-950">
                                                Phone Number
                                            </label>
                                            <input
                                                type="tel"
                                                className=" block h-9 w-full rounded-md border border-solid mt-2 border-gray-300 /80 px-3 py-6 pl-4 text-sm text-black focus:outline-none focus:right-0"
                                                onChange={(e) => setPhone(e.target.value)}
                                                value={phone}
                                            />
                                            <small>
                                                <b className='font-semibold text-gray-400'>
                                                    Please add country prefix (+00)
                                                </b>
                                            </small>
                                        </div>
                                        <div >
                                            <label htmlFor="name-2" className="mb-1 font-medium text-indigo-950">
                                                When do you want us to call you?
                                            </label>
                                            <input
                                                type="date"
                                                id="date-picker"
                                                value={selectedDate}
                                                onChange={handleChange}
                                                min={today}
                                                className="mb-4 block h-9 w-full rounded-md border border-solid mt-2 border-gray-300 /80 px-3 py-6 pl-4 text-sm text-black focus:outline-none focus:right-0"
                                            />
                                        </div>
                                        <button type="submit" className="w-full flex flex-row justify-between items-center cursor-pointer rounded-md bg-black px-6 py-4 text-start font-semibold text-white">
                                            <span>
                                                Schedule
                                            </span>
                                            <ArrowRight className='size-4' />
                                        </button>
                                    </form>
                                </>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}
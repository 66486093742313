// CartContext.js

import React, { createContext, useState, useContext, useEffect } from 'react';

// Creamos el contexto
const CartContext = createContext();

// Crear un proveedor que envuelva nuestra aplicación
export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    try {
      // Intentar cargar el carrito desde localStorage
      const savedCart = localStorage.getItem('cart');
      return savedCart ? JSON.parse(savedCart) : [];
    } catch (error) {
      console.error("Error al cargar el carrito:", error);
      return [];
    }
  });


  // Función para agregar un producto al carrito
  const addToCart = (id, name = '', price = 100) => {
    setCartItems(prevItems => {
      let currentCart = [];

      try {
        // Intentar cargar el carrito actual desde localStorage
        const storedCart = localStorage.getItem('cart');
        currentCart = storedCart ? JSON.parse(storedCart) : [];
      } catch (error) {
        console.error("Error al leer el carrito de localStorage:", error);
        currentCart = [];
      }

      // Verificar si el producto ya existe
      const productIndex = currentCart.findIndex(item => item.id === id);

      if (productIndex === -1) {
        // Si no existe, agregarlo
        const updatedCart = [...currentCart, { id, name, quantity: 1, price }];
        // Guardar el carrito actualizado en localStorage
        localStorage.setItem('cart', JSON.stringify(updatedCart));
        return updatedCart;
      } else {
        // Si existe, aumentar la cantidad
        const updatedCart = [...currentCart];
        updatedCart[productIndex].quantity += 1;
        // Guardar el carrito actualizado en localStorage
        localStorage.setItem('cart', JSON.stringify(updatedCart));
        return updatedCart;
      }
    });
  };


  // Función para eliminar un producto del carrito
  const removeFromCart = (id) => {
    setCartItems(prevItems => {
      const updatedCart = prevItems.filter(item => item.id !== id);
      // Guardar el carrito actualizado en localStorage
      localStorage.setItem('cart', JSON.stringify(updatedCart));
      return updatedCart;
    });
  };

  // Función para obtener el total de productos
  const getTotalItems = () => {
    return cartItems.reduce((total, item) => total + item.quantity, 0);
  };

  return (
    <CartContext.Provider value={{ cartItems, addToCart, removeFromCart, getTotalItems }}>
      {children}
    </CartContext.Provider>
  );
};

// Hook para usar el contexto en cualquier componente
export const useCart = () => useContext(CartContext);

export default function Morosidad() {

    const delinquencyPolicy = [
        {
            id: 1,
            title: "Purpose",
            content: "Ark Platforms, Inc. has established this policy to manage overdue accounts and ensure timely payments for services such as Web Development, Software, Applications, and 3D Design. Timely payments allow us to maintain the quality and efficiency of our services."
        },
        {
            id: 2,
            title: "Scope",
            content: "This policy applies to all clients of Ark Platforms, Inc. who have unpaid balances beyond the agreed payment terms. The policy is intended to maintain a fair and efficient payment process."
        },
        {
            id: 3,
            title: "Terms of Payment",
            content: "Invoices are generally due within 30 days of issuance. If payments are not received by this due date, the account will be classified as overdue, and additional fees may apply."
        },
        {
            id: 4,
            title: "Overdue Notifications",
            content: "Clients will receive an email reminder after 30 days of non-payment. After 45 days, a final notice will be issued, notifying the client of potential penalties and service suspension if the balance remains unpaid."
        },
        {
            id: 5,
            title: "Grace Period",
            content: "A 15-day grace period following the due date may be extended to clients with a history of timely payments. During this time, no penalties will apply, but continued service may be contingent on payment."
        },
        {
            id: 6,
            title: "Penalties and Administrative Fees",
            content: "A $200 administrative fee will be charged to accounts exceeding 45 days overdue. This fee helps cover the costs of managing delinquent accounts and is in addition to the initial service charges."
        },
        {
            id: 7,
            title: "Service Suspension",
            content: "If an account remains unpaid after 45 days, Ark Platforms, Inc. reserves the right to suspend any ongoing services until full payment is received. This includes all Web, Software, App, or 3D Design services under the client's contract."
        },
        {
            id: 8,
            title: "Interest on Outstanding Balances",
            content: "An interest rate of 1.5% per month may be applied to balances exceeding 60 days past due. This interest is calculated monthly and added to the overdue balance until paid in full."
        },
        {
            id: 9,
            title: "Collection Efforts",
            content: "Accounts remaining unpaid for more than 60 days may be turned over to a third-party collection agency. Ark Platforms, Inc. reserves the right to pursue legal remedies to recover the outstanding debt."
        },
        {
            id: 10,
            title: "Legal Fees",
            content: "Clients will be responsible for any legal fees or collection costs incurred by Ark Platforms, Inc. in the recovery of overdue balances."
        },
        {
            id: 11,
            title: "Reactivation of Services",
            content: "Once an overdue account is paid in full, services may be reactivated at the discretion of Ark Platforms, Inc., depending on resource availability and project schedules."
        },
        {
            id: 12,
            title: "Dispute Resolution",
            content: "Clients may dispute any charges within 15 days of invoice receipt. Ark Platforms, Inc. will review disputes and provide a resolution based on evidence and terms agreed upon in the service contract."
        },
        {
            id: 13,
            title: "Payment Methods",
            content: "Ark Platforms, Inc. accepts various payment methods including bank transfer, credit card, and online payment services. Clients are responsible for any transaction fees associated with their chosen payment method."
        },
        {
            id: 14,
            title: "Invoice Adjustments",
            content: "Any adjustments to invoices due to errors or service amendments must be requested within 10 days of invoice issuance. Adjusted invoices will have a new payment term based on the updated issuance date."
        },
        {
            id: 15,
            title: "Credit Terms",
            content: "Ark Platforms, Inc. may offer extended credit terms on a case-by-case basis to clients with a strong payment history. These terms are subject to annual review and may be adjusted at the discretion of Ark Platforms, Inc."
        },
        {
            id: 16,
            title: "Termination of Services",
            content: "Failure to resolve an overdue account may result in the termination of all services provided by Ark Platforms, Inc., with no guarantee of resuming the project once payment is made."
        },
        {
            id: 17,
            title: "Refund Policy",
            content: "Payments for services rendered are non-refundable. In cases where services have been prepaid but not yet delivered, clients may request a refund within the bounds of the service contract."
        },
        {
            id: 18,
            title: "Modification of Terms",
            content: "Ark Platforms, Inc. reserves the right to modify this policy at any time. Updated versions will be posted on our website, and clients are encouraged to review the policy regularly."
        },
        {
            id: 19,
            title: "Policy Acknowledgment",
            content: "By engaging with Ark Platforms, Inc., clients acknowledge and agree to this delinquency policy and its terms, including penalties, interest, and potential legal actions."
        },
        {
            id: 20,
            title: "Contact Information",
            content: "For any questions regarding this Delinquency Policy or to discuss payment arrangements, please contact us at: info@ark-platforms.com."
        }
    ];


    return (
        <section>
            <div className="flex flex-col items-center justify-center px-5 md:px-10">
                {/* Title Container */}
                <div className="flex h-auto min-w-[90vw] flex-col items-center justify-end bg-gray-100 rounded-b-3xl py-6 md:h-64">
                    <div className="flex flex-col items-center gap-y-4 py-5">
                        <h1 className="text-3xl font-bold md:text-5xl">Late Payment Policy</h1>
                        <p className="text-sm text-[#808080] sm:text-base">Last Updated as of September 18, 2024</p>
                    </div>
                </div>
                {/* Content Container */}
                <div className="mx-auto w-full max-w-5xl py-12 md:py-16 lg:py-20">
                    {/* Component */}
                    <div className="flex flex-col items-center gap-y-14">
                        <p className="max-w-full text-start text-sm sm:text-base">The Late Payment Policy is a set of rules and guidelines that regulate the management of overdue accounts and compliance with payments by clients. It establishes the rights and responsibilities of both Ark Platforms, Inc. and its clients, defining payment terms, penalties for delays, service suspension measures and reactivation processes. This policy is essential to ensure a clear understanding and mutual agreement between the parties, protecting the interests of the company and promoting the financial responsibility of clients to maintain the continuity and quality of services.</p>
                        <div className="flex min-w-full flex-col gap-y-10">
                            <div className="flex min-w-full py-4 [border-bottom:1px_solid_rgb(226,_226,_226)]">
                                <h6 className="text-base font-bold uppercase">Late Payment Policy &amp; CONDITIONS</h6>
                            </div>
                            <div className="flex flex-col gap-y-10">
                                <div className="flex min-w-full flex-col items-start gap-y-6">
                                    {delinquencyPolicy.map(term => (
                                        <div key={term.id} className="flex flex-col items-start gap-y-3">
                                            <p className="text-xl font-semibold">{term.title}</p>
                                            <p className="text-sm">{term.content}</p>
                                        </div>
                                    ))}
                                </div>
                                <div className="min-h-[1px] min-w-full bg-[#e2e2e2]"></div>
                                <p className="text-sm">By accessing, browsing, or utilizing any design services, communication channels, or materials provided by Ark Platforms, Inc, including but not limited to graphic design, web design, branding, illustration, and user interface design, whether through our website, email, phone, or any other means, you expressly acknowledge, understand, and agree that you have carefully read, comprehended, and fully consent to be legally bound by all the provisions, terms, and conditions set forth in these Terms of Service, including any additional agreements, policies, guidelines, or amendments referenced or incorporated herein.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}
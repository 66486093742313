export default function Privacy() {

    const privacyPolicy = [
        {
            "id": 1,
            "title": "Introduction",
            "content": "Welcome to Ark Platforms, Inc. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our services. By accessing our services, you agree to the terms outlined in this policy."
        },
        {
            "id": 2,
            "title": "Information We Collect",
            "content": "We collect information you provide directly to us, such as when you create an account, make a purchase, or contact us. This may include personal details such as your name, email address, phone number, and payment information. We also collect information automatically through cookies and similar technologies to enhance your experience on our site."
        },
        {
            "id": 3,
            "title": "How We Use Your Information",
            "content": "We use your information to provide and improve our services, process transactions, communicate with you, and respond to your inquiries. Additionally, we may use your data for marketing purposes, such as sending you promotional offers and updates, but you can opt out of receiving such communications at any time."
        },
        {
            "id": 4,
            "title": "Data Security",
            "content": "We implement a variety of security measures to maintain the safety of your personal information. This includes encryption, secure servers, and access controls. However, please be aware that no security measures are completely foolproof and we cannot guarantee the absolute security of your information."
        },
        {
            "id": 5,
            "title": "Sharing Your Information",
            "content": "We do not sell or rent your personal information to third parties. However, we may share your information with trusted partners and service providers who assist us in operating our website and providing our services. We may also disclose your information if required by law or to protect our rights."
        },
        {
            "id": 6,
            "title": "Cookies and Tracking Technologies",
            "content": "Our website uses cookies and other tracking technologies to enhance your browsing experience and collect data about site usage. You can manage your cookie preferences through your browser settings. Note that disabling cookies may affect the functionality of our website."
        },
        {
            "id": 7,
            "title": "Third-Party Links",
            "content": "Our website may contain links to third-party sites. We are not responsible for the privacy practices or content of these external sites. We encourage you to review the privacy policies of any third-party sites you visit."
        },
        {
            "id": 8,
            "title": "Children's Privacy",
            "content": "Our services are not intended for children under the age of 13. We do not knowingly collect personal information from children. If we become aware that we have collected information from a child, we will take steps to delete it promptly."
        },
        {
            "id": 9,
            "title": "Changes to This Policy",
            "content": "We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. Your continued use of our services after changes are made signifies your acceptance of the updated policy."
        },
        {
            "id": 10,
            "title": "Contact Us",
            "content": "If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at [contact email] or [contact address]. We are committed to addressing your inquiries and resolving any issues you may have."
        }
    ];
    


    return (
        <section>
            <div className="flex flex-col items-center justify-center px-5 md:px-10">
                {/* Title Container */}
                <div className="flex h-auto min-w-[90vw] flex-col items-center justify-end bg-gray-100 rounded-b-3xl py-6 md:h-64">
                    <div className="flex flex-col items-center gap-y-4 py-5">
                        <h1 className="text-3xl font-bold md:text-5xl">Privacy Policy</h1>
                        <p className="text-sm text-[#808080] sm:text-base">Last Updated as of September 18, 2024</p>
                    </div>
                </div>
                {/* Content Container */}
                <div className="mx-auto w-full max-w-5xl py-12 md:py-16 lg:py-20">
                    {/* Component */}
                    <div className="flex flex-col items-center gap-y-14">
                        <p className="max-w-full text-start text-sm sm:text-base">Our Privacy Policy outlines how Ark Platforms, Inc. collects, uses, and protects your personal information when you interact with our website and services. It details the types of data we gather, the purposes for which we use it, and the measures we take to ensure its security. We are committed to safeguarding your privacy and providing transparency regarding our data practices. By using our services, you agree to the terms of this policy. For any questions or concerns, please refer to the Contact Us section of our policy.</p>
                        <div className="flex min-w-full flex-col gap-y-10">
                            <div className="flex min-w-full py-4 [border-bottom:1px_solid_rgb(226,_226,_226)]">
                                <h6 className="text-base font-bold">GENERAL PRIVACY POLICY</h6>
                            </div>
                            <div className="flex flex-col gap-y-10">
                                <div className="flex min-w-full flex-col items-start gap-y-6">
                                    {privacyPolicy.map(term => (
                                        <div key={term.id} className="flex flex-col items-start gap-y-3">
                                            <p className="text-xl font-semibold">{term.title}</p>
                                            <p className="text-sm">{term.content}</p>
                                        </div>
                                    ))}
                                </div>
                                <div className="min-h-[1px] min-w-full bg-[#e2e2e2]"></div>
                                <p className="text-sm">By accessing, browsing, or utilizing any design services, communication channels, or materials provided by Ark Platforms, Inc, including but not limited to graphic design, web design, branding, illustration, and user interface design, whether through our website, email, phone, or any other means, you expressly acknowledge, understand, and agree that you have carefully read, comprehended, and fully consent to be legally bound by all the provisions, terms, and conditions set forth in these Terms of Service, including any additional agreements, policies, guidelines, or amendments referenced or incorporated herein.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}
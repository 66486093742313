import { doc, getDoc, setDoc } from 'firebase/firestore';
import { ArrowRight } from 'iconsax-react';
import { Users, DollarSign, Star, ListTodo, UserRoundCheck } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../db/FirebaseConfig';

export default function Affiliates() {
  const benefits = [
    {
      id: 1,
      title: 'Earn High Commissions',
      description:
        'Earn generous rewards for every successful referral! Enjoy a commission of up to 20% on sales and unlock great earning potential with each referral.',
      icon: DollarSign,
    },
    {
      id: 2,
      title: 'Build Valuable Networks',
      description:
        'Collaborate with a community of affiliates and grow your professional network.',
      icon: Users,
    },
    {
      id: 3,
      title: 'Exclusive Rewards',
      description:
        'Unlock special bonuses and perks as you climb higher in our affiliate tiers.',
      icon: Star,
    },
  ];

  const affiliateProcessSteps = [
    {
      id: 1,
      title: "Submit Affiliate Application",
      description: "Complete the application form to join our affiliate program."
    },
    {
      id: 2,
      title: "Receive Confirmation",
      description: "You will get a response regarding your application status."
    },
    {
      id: 3,
      title: "Access the Software",
      description: "If approved, you will be granted software access to upload tasks."
    },
    {
      id: 4,
      title: "Earn Commissions on Work",
      description: "Commission rates depend on the task value. E.g., a $300 site earns 10%."
    },
    {
      id: 5,
      title: "Ark Sends Payment Link",
      description: "Ark will send a payment link to the client and deliver the work."
    },
    {
      id: 6,
      title: "Track Commissions Online",
      description: "Monitor your earned commissions in real-time at affiliates.ark-platforms.com."
    }
  ];

  const [openFAQ, setOpenFAQ] = useState(null);

  // Function to toggle FAQ open/close
  const toggleFAQ = (index) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  // List of FAQs
  const faqs = [
    {
      question: "How can I become an affiliate?",
      answer:
        "Simply fill out the affiliate application form on our website to get started. Once approved, you'll gain access to our platform.",
    },
    {
      question: "How much commission can I earn as an affiliate?",
      answer:
        "Affiliates can earn commissions ranging from 5% to 20%, depending on the type of project they refer to us.",
    },
    {
      question: "How do I track my affiliate earnings?",
      answer:
        "You can track your commissions in real-time through our affiliate platform at affiliates.ark-platforms.com.",
    },
    {
      question: "How and when do I get paid?",
      answer:
        "Payments are processed monthly, and you have multiple options for receiving your commission: Western Union, Bank Transfer (available in any country), or PayPal.",
    },
    {
      question: "What types of services can I promote as an affiliate?",
      answer:
        "You can promote our full range of services, including website development, software solutions, app development, and more.",
    },
    {
      question: "Is there a referral bonus for bringing in new affiliates?",
      answer:
        "Yes, we offer bonuses for successful affiliate referrals. Check our affiliate dashboard for more details on our referral program.",
    },
    {
      question: "Can I promote Ark’s services on my own website?",
      answer:
        "Yes, as an affiliate, you're free to promote our services on your website, social media, or through any other marketing channel.",
    },
    {
      question: "Do I need special skills to become an affiliate?",
      answer:
        "No, anyone can become an affiliate. We provide all the necessary resources, including banners and referral links, to help you get started.",
    },
    {
      question: "What happens if a customer I referred cancels their order?",
      answer:
        "If a customer cancels their order, the commission will not be paid out. You only earn commissions on completed and paid orders.",
    },
    {
      question: "Can I track my leads and referrals?",
      answer:
        "Yes, the affiliate dashboard allows you to track your leads, referrals, and commissions at any time.",
    },
  ];

  const nextSteps = [
    {
      step: 1,
      title: "Application Review",
      description: "Our team will carefully review the details you provided to assess your eligibility for the program."
    },
    {
      step: 2,
      title: "Response via Email",
      description: "Once the review is complete, you will receive an email with our decision. Please make sure to check your inbox regularly (and your spam folder just in case)."
    },
    {
      step: 3,
      title: "Access to Software (Upon Approval)",
      description: "If your application is approved, you will receive access to our affiliate software. This tool will allow you to upload tasks and manage your affiliate activities efficiently."
    },
    {
      step: 4,
      title: "Next Steps Guide",
      description: "Along with access to the software, we'll send detailed instructions to help you get started and make the most of your affiliate partnership."
    },
    {
      step: 5,
      title: "Contact Us for Support",
      description: "If you have any questions in the meantime, feel free to contact our support team. We’re excited to potentially have you join our affiliate family!"
    }
  ];


  // State to store form data
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [message, setMessage] = useState("");
  const [isTemporaryEmail, setIsTemporaryEmail] = useState(null)
  const [sended, setSended] = useState(null)
  const [countries, setCountries] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(""); 
  const [isAlreadyRegistered, setIsAlreadyRegistered] = useState(null);
  const [searchQuery, setSearchQuery] = useState(""); // Texto del input
  const [loading, setLoading] = useState(false); //
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Fetch countries from the API
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        const data = await response.json();
        const countryList = data.map((country) => ({
          name: country.name.common,
          flag: country.flags.png,
          code: country.cca2, // ISO country code
        }));
        setCountries(countryList.sort((a, b) => a.name.localeCompare(b.name)));
        setFilteredCountries(countryList);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  console.log(searchQuery);
  console.clear();
  // Handle search input changes
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setSelectedCountry(query); // Actualizamos el valor del input

    // Filtrar países por el texto ingresado
    const filtered = countries.filter((country) =>
      country.name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredCountries(filtered);
  };

  // Handle country selection
  const handleCountrySelect = (countryName) => {
    setSelectedCountry(countryName); // Actualizamos el valor del input
    setSearchQuery(countryName); // Sincronizamos el valor del texto del input
    setIsDropdownOpen(false); // Cerramos el dropdown
  };


  const checkTemporaryEmail = async (e) => {
    // Llamamos a la API de verificación de correo electrónico
    try {
      const response = await fetch(
        `https://www.validator.pizza/email/${e}`
      );
      const data = await response.json();
      switch (data.disposable) {
        case true:
          setIsTemporaryEmail(true);
          break;
        case false:
          setIsTemporaryEmail(false);
          break;
        default:
          setIsTemporaryEmail(null); // Devuelve null si el resultado no es booleano
      }
      return data.disposable;
    } catch (error) {
      console.error("Error verificando correo:", error);
      return false; // Devuelve falso en caso de error
    }
  };

  const registerLikeFiliate = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setSended(false);

      // Referencia al documento con el ID del correo
      const docRef = doc(db, "affiliates", email);

      // Verificar si el correo ya existe
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setLoading(false);
        setSended(true);
        setIsAlreadyRegistered(true);
      }

      // Registrar el nuevo afiliado
      await setDoc(docRef, {
        name: name,
        email: email,
        country: selectedCountry,
        website: website,
        message: message,
      });

      setSended(true);
    } catch (error) {
      console.error("Error registering affiliate:", error);
      alert("There was an error while registering your information. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <main>
      {/* Hero Section */}
      <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-20">
        <div className="grid grid-cols-1 items-center gap-8 sm:gap-20 lg:grid-cols-2">
          <div className="max-w-3xl">
            <h1 className="mb-6 pb-4 text-4xl font-bold md:text-6xl">
              Become an Affiliate Partner
            </h1>
            <p className="mb-6 max-w-lg text-gray-500 md:mb-10 lg:mb-12">
              Join our affiliate program and earn lucrative commissions by sharing our products and services. Start building your revenue stream today!
            </p>
            <a
              href="#apply"
              className="mb-5 mr-5 inline-flex flex-row gap-6 items-center justify-between rounded-full bg-black px-6 py-4 text-center font-medium text-white transition hover:bg-gray-800 lg:mb-8 lg:mr-8"
            >
              Apply Now
              <ArrowRight />
            </a>
          </div>
          <div>
            <img
              src="images/team.avif"
              alt="Affiliates program"
              className="mx-auto inline-block rounded-3xl h-full w-full max-w-2xl object-cover"
            />
            <small className='text-gray-400 capitalize'>
              Ark Team - United States
            </small>
          </div>
        </div>
      </div>

      {/* Benefits Section */}
      <section>
        <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-20">
          <div className="flex flex-col items-center text-center">
            <h2 className="text-3xl font-bold md:text-5xl">
              Why Join Our Affiliate Program?
            </h2>
            <p className="mb-8 mt-4 max-w-3xl text-base text-gray-500 md:mb-12 md:text-lg lg:mb-16">
              Unlock exclusive opportunities to earn and grow. Our affiliate program is designed to help you succeed.
            </p>
          </div>
          <div className="grid gap-5 sm:grid-cols-2 md:grid-cols-3 md:gap-4 lg:gap-6">
            {benefits.map((benefit, index) => {
              const IconComponent = benefit.icon;
              return (
                <div
                  key={benefit.id}
                  className="grid gap-6 rounded-3xl border border-solid border-gray-300 p-8 md:p-10"
                >
                  <div className="w-16 h-16 bg-gray-100 rounded-full flex justify-center items-center">
                    <IconComponent className="w-8 h-8 text-black" />
                  </div>
                  <h3 className="text-xl font-semibold">{benefit.title}</h3>
                  <p className="text-sm text-gray-500">{benefit.description}</p>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/** STEPS */}
      <section>
        <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-20">
          <div className="flex flex-col items-center text-center">
            <h2 className="text-3xl font-bold md:text-5xl">
              How to become an affiliate?
            </h2>
            <p className="mb-8 mt-4 max-w-3xl text-base text-gray-500 md:mb-12 md:text-lg lg:mb-16">
              Steps to become an affiliate
            </p>
          </div>
          <div className="grid gap-5 sm:grid-cols-2 md:grid-cols-3 md:gap-4 lg:gap-6">
            {affiliateProcessSteps.map((steps, index) => {
              return (
                <div
                  key={index}
                  className="grid gap-4 rounded-3xl border border-solid border-gray-300 p-8 md:p-10"
                >
                  <div className="w-16 h-16 bg-gray-100 rounded-full flex justify-center items-center">
                    <h1 className='text-2xl font-bold'>{steps.id}</h1>
                  </div>
                  <h3 className="text-xl font-semibold">{steps.title}</h3>
                  <p className="text-sm text-gray-500">
                    {steps.description.split("affiliates.ark-platforms.com").map((part, index) => (
                      <>
                        {part}{" "}
                        {index < steps.description.split("affiliates.ark-platforms.com").length - 1 && (
                          <a
                            href="https://affiliates.ark-platforms.com"
                            className="font-medium text-blue-500 group relative inline-block transition-all"
                          >
                            affiliates.ark-platforms.com
                            <span className="absolute left-0 bottom-0 w-full h-[1px] bg-blue-500 scale-x-0 group-hover:scale-x-100 origin-left transition-transform duration-300"></span>
                          </a>
                        )}
                      </>
                    ))}
                  </p>

                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/** FAQS */}
      <section>
        <div className="mx-auto flex w-full max-w-7xl flex-col items-center justify-center px-5 py-16 md:px-10 md:py-20">
          <div className="mx-auto flex max-w-xl flex-col items-center justify-center px-6 text-center lg:max-w-3xl lg:px-10">
            <h1 className="text-3xl font-bold md:text-5xl">
              Frequently Asked Questions
            </h1>
            <p className="font-inter mt-4 max-w-xl px-5 text-base font-light text-gray-500 lg:max-w-lg">
              Most frequently asked questions by our affiliates
            </p>
          </div>
          <div className="mt-10 flex w-full max-w-4xl flex-col">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className="relative my-3 w-full rounded-3xl border border-gray-300 px-6 py-8"
              >
                <div className="max-w-3xl">
                  <h2 className="text-xl font-bold text-black">{faq.question}</h2>
                  {openFAQ === index && (
                    <p className="font-inter mt-4 text-base font-light text-gray-500">
                      {faq.answer}
                    </p>
                  )}
                </div>
                <button
                  className="absolute right-5 top-9 focus:outline-none"
                  onClick={() => toggleFAQ(index)}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="12" fill="white"></circle>
                    <path
                      d="M7.04688 11.9999H16.9469"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M12 7.05005V16.95"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className={`${openFAQ === index ? "opacity-0" : "opacity-100"} transition-opacity duration-100 ease-in-out`}
                    ></path>
                  </svg>
                </button>
              </div>
            ))}
          </div>
          <p className="font-inter mx-auto mt-8 text-base text-gray-500 text-center">
            Can’t find the answer you’re looking for? Reach out to our
            <Link to="/contact-us" className="text-black font-bold">
              {" "}
              customer support team.
            </Link>
          </p>
        </div>
      </section>


      {/* Application Form */}
      <section id="apply" className="py-16 md:py-20">
        <div className="mx-auto w-full max-w-7xl px-5 md:px-10">
          <div className="text-center">
            <h2 className="text-3xl font-bold md:text-5xl">
              Become an Affiliate
            </h2>
            <p className="mt-4 mb-8 mx-auto text-gray-500 text-sm sm:text-base text-wrap max-w-5xl">
              Fill out the form below to start your journey as an affiliate. Our team will review your application and get back to you shortly.
            </p>
          </div>
          {
            sended ?
              <>
                <main className="max-w-7xl mx-auto border rounded-3xl space-y-4 p-8 md:p-12">
                  {
                    isAlreadyRegistered ? 
                    <>
                        <div className='w-full flex flex-col md:flex-row justify-start items-start md:items-center gap-4'>
                          <div className='w-20 h-20 bg-gray-200 rounded-full flex justify-center items-center'>
                            <UserRoundCheck size={35} />
                          </div>
                          <div>
                            <h1 className='text-3xl font-bold md:text-4xl'>
                              You already have an application in progress...
                            </h1>
                          </div>
                        </div>
                        <p className="text-sm text-gray-500">
                          Your application is already in progress, which means our team is currently reviewing the details you provided. Once we complete the review process, you will receive an update on the next steps directly via the email address you used to apply. We appreciate your patience and are excited about the possibility of having you join our affiliate program!
                        </p>
                    </>
                    :
                    <>
                        <div className='w-full flex flex-col md:flex-row justify-start items-start md:items-center gap-4'>
                          <div className='w-20 h-20 bg-gray-100 rounded-full flex justify-center items-center'>
                            <ListTodo size={35} />
                          </div>
                          <div>
                            <h1 className='text-3xl font-bold md:text-4xl'>
                              Thanks for Your Application!
                            </h1>
                            <small>
                              Your request has been successfully registered in our system. Here's what to expect next:
                            </small>
                          </div>
                        </div>
                        <p className='text-gray-700 text-sm font-normal'>
                          We have successfully received your request. Our team will review it, and you can expect a response within 24 to 48 hours. If your application is approved, you will receive the following information via email:
                        </p>
                        <ul className='list-disc text-gray-700 text-sm font-normal px-8 space-y-4'>
                          <li>
                            Password
                          </li>
                          <li>
                            Affiliate URL
                          </li>
                          <li>
                            Your unique affiliate ID
                          </li>
                          <li>
                            Affiliate letter
                          </li>
                        </ul>
                        <p className='text-gray-700 text-sm font-normal'>
                          Thanks, Ark Team
                        </p>
                    </>
                  }
                </main>
              </> :
              <>
                <form onSubmit={registerLikeFiliate} className="max-w-7xl mx-auto border rounded-3xl  p-8 md:p-12">
                  <div className="grid gap-6 md:grid-cols-2">
                    {/* Name Field */}
                    <div className="flex flex-col">
                      <label htmlFor="name" className="mb-2 text-sm font-medium text-gray-700">
                        Full Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        className="w-full border p-4 border-gray-200 rounded-xl focus:outline-none focus:ring-0 focus:border-gray-200"
                        placeholder="Enter your name"
                        required
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                    {/* Email Field */}
                    <div className="flex flex-col">
                      <label htmlFor="email" className="mb-2 text-sm font-medium text-gray-700">
                        Email Address
                      </label>
                      <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          checkTemporaryEmail(e.target.value);
                        }}
                        className={`w-full border p-4 rounded-xl focus:outline-none focus:ring-0 focus:border-gray-200 ${isTemporaryEmail ? "border-red-200" : "border-gray-200"}`}
                        placeholder="Enter your email"
                        required
                      />
                      <small>
                        {isTemporaryEmail && (
                          <span className="text-red-500 text-xs">
                            This email address may not be valid.
                          </span>
                        )}
                      </small>
                    </div>
                    {/* Phone Field */}
                    <div className="flex flex-col">
                      <label htmlFor="country" className="mb-2 text-sm font-medium text-gray-700">
                        Country
                      </label>
                      <div className="relative w-full">
                        {/* Input Field */}
                        <input
                          type="text"
                          id='country'
                          value={selectedCountry}
                          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                          onChange={handleSearchChange}
                          required
                          placeholder="Select your country"
                          className="w-full border p-4 rounded-xl focus:outline-none border-gray-200 focus:ring-0 focus:border-gray-200"
                        />

                        {/* Dropdown */}
                        {isDropdownOpen && (
                          <div className="absolute z-10 mt-2 w-full bg-white border rounded-xl shadow-lg max-h-60 overflow-y-auto">
                            {filteredCountries.map((country, index) => (
                              <button
                                key={index}
                                onClick={() => handleCountrySelect(country.name)}
                                className="flex items-center w-full px-4 py-2 hover:bg-gray-100"
                              >
                                <img
                                  src={country.flag}
                                  alt={country.name}
                                  className="w-6 h-4 mr-3"
                                />
                                <span className="text-sm text-gray-700">{country.name}</span>
                              </button>
                            ))}

                            {/* No Results Found */}
                            {filteredCountries.length === 0 && (
                              <div className="px-4 py-2 text-sm text-gray-500">
                                No countries found.
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    {/* Website/Portfolio Field */}
                    <div className="flex flex-col">
                      <label htmlFor="website" className="mb-2 text-sm font-medium text-gray-700">
                        Website or Portfolio (optional)
                      </label>
                      <input
                        type="url"
                        id="website"
                        value={website}
                        required={false}
                        onChange={(e) => setWebsite(e.target.value)}
                        className="w-full border p-4 border-gray-200 rounded-xl focus:outline-none focus:ring-0 focus:border-gray-200"
                        placeholder="Enter your website"
                      />
                    </div>
                  </div>

                  <div className="mt-6 flex flex-col">
                    <label htmlFor="message" className="mb-2 text-sm font-medium text-gray-700">
                      Tell us why you'd like to join
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      rows="5"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      className="w-full border p-4 border-gray-200 rounded-xl focus:outline-none focus:ring-0 focus:border-gray-200"
                      placeholder="Share your interest and goals"
                      required
                    ></textarea>
                  </div>

                  <div className="mt-8">
                    <button
                      type="submit"
                      disabled={isTemporaryEmail ? true : false}
                      className="rounded-full bg-black px-6 py-4 text-white font-medium hover:bg-gray-800"
                    >
                      {
                        loading ? 'Sending...' : 'Submit Application'
                      }
                    </button>
                  </div>
                </form>
              </>
          }
        </div>
      </section>
    </main>
  );
}

import { ArrowRight } from "iconsax-react";
import { ShoppingCart } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCart } from "../utils/cartUtils";


const Navbar = ({ setOpenCart }) => {
    const [navIsOpened, setNavIsOpened] = useState(false);
    const [date, setDate] = useState(true); // Se inicia en true

    useEffect(() => {
      const checkDate = () => {
        const today = new Date();
        const cutoffDate = new Date(today.getFullYear(), 10, 16, 23, 59, 59); // 16 de noviembre, 23:59:59
  
        // Si la fecha actual es igual o superior al 16 de noviembre, cambiar `date` a false
        if (today >= cutoffDate) {
          setDate(false);
        } else {
          setDate(true);
        }
      };
  
      checkDate(); // Llamamos a la función una vez al montar el componente
    }, []);

    const closeNavbar = () => {
        setNavIsOpened(false);
    };

    const toggleNavbar = () => {
        setNavIsOpened((navIsOpened) => !navIsOpened);
    };

    const menuItems = [
        { label: "Web Development", to: "/web" },
        { label: "Purchase Domain", to: "/#domain" },
        { label: "Design Services", to: "/design" },
        { label: "App Development", to: "/app" },
        { label: "Software Solutions", to: "/software" },
        { label: "Ark Tools Solutions", to: "/our-tools" },
    ];

    const sortedMenuItems = [...menuItems].sort((a, b) =>
        a.label.localeCompare(b.label)
    );

    // Función para manejar el clic en el botón
    const handleCartClick = () => {
        setOpenCart(true); 
    };

    const { getTotalItems } = useCart();
    const [totalItems, setTotalItems] = useState(0);

    // Actualizar el total de productos en el carrito cuando cambia
    useEffect(() => {
        const total = getTotalItems();
        setTotalItems(total);
    }, [getTotalItems]);

    return (
        <>
        {
            date && (
                <div className="bg-gray-800">
                <div className="max-w-[85rem] px-4 py-4 sm:px-6 lg:px-8 mx-auto text-center">
                    <Link to={'/web'} className="group inline-flex flex-wrap items-center bg-white/80 hover:bg-white/10 focus:outline-none focus:bg-white/10 border border-white/10 p-1 ps-4 rounded-full shadow-md" href="../figma.html">
                        <p className="me-2 text-white text-sm">
                        Web pages for only $80 until November 16!
                        </p>
                        <span className="group-hover:bg-white/10 group-focus:bg-white/10 py-1.5 px-2.5 inline-flex justify-center items-center gap-x-2 rounded-full bg-white/10 font-semibold text-white text-sm">
                            <svg className="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="m9 18 6-6-6-6" /></svg>
                        </span>
                    </Link>
                </div>
            </div>
            )
        }
            <div
                aria-hidden={true}
                onClick={() => {
                    closeNavbar();
                }}
                className={`fixed bg-gray-800/40 inset-0 z-30 ${navIsOpened ? "lg:hidden" : "hidden lg:hidden"
                    }`}
            />
            <header className="sticky top-0 w-full left-0 flex items-center h-20 border-b border-b-gray-100  z-40 bg-white lg:bg-white/80  backdrop-filter backdrop-blur-2xl">
                <nav className="relative mx-auto lg:max-w-7xl w-full px-5 sm:px-10 md:px-12 lg:px-5 flex gap-x-5 justify-between items-center">
                    <div className="flex items-center min-w-max">
                        <Link to="/" className="relative flex items-center gap-2.5">
                        <img src="logo.svg" alt="" className="w-16 h-16" />
                            <div className="w-auto flex flex-col">
                                <span className="inline-flex text-lg font-medium text-black ">
                                    Ark Platforms, Inc
                                </span>
                                <small className="text-gray-400 text-xs hidden md:block lg:block">
                                    {new Date().getFullYear()} © Ark Platforms, Inc. All rights reserved.
                                </small>
                            </div>
                        </Link>
                    </div>
                    <div
                        className={`
              absolute top-full left-0 bg-white dark:bg-black lg:bg-transparent border-b border-gray-200  py-8 lg:py-0 px-5 sm:px-10 md:px-12 lg:px-0 lg:border-none w-full lg:top-0 lg:relative lg:w-max lg:flex lg:transition-none duration-300 ease-linear gap-x-6
              ${navIsOpened
                                ? "visible opacity-100 translate-y-0"
                                : "translate-y-10 opacity-0 invisible lg:visible lg:translate-y-0 lg:opacity-100"
                            }
            `}
                    >
                        <ul className="flex flex-col lg:flex-row gap-6 lg:items-center text-gray-700  lg:w-full lg:justify-center">
                            <li>
                                <Link to={'/'}

                                    className="relative py-2.5 duration-300 dark:text-gray-200 ease-linear hover:text-black font-medium after:absolute after:w-full after:left-0 after:bottom-0 after:h-px after:rounded-md after:origin-left after:ease-linear after:duration-300 after:scale-x-0 hover:after:scale-x-100 after:bg-black"
                                >
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={'/about-us'}
                                    className="relative py-2.5 dark:text-gray-200 duration-300 ease-linear hover:text-black font-medium after:absolute after:w-full after:left-0 after:bottom-0 after:h-px after:rounded-md after:origin-left after:ease-linear after:duration-300 after:scale-x-0 hover:after:scale-x-100 after:bg-black"
                                >
                                    About Us
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={'/affiliates'}
                                    className="relative py-2.5 dark:text-gray-200 duration-300 ease-linear hover:text-black font-medium after:absolute after:w-full after:left-0 after:bottom-0 after:h-px after:rounded-md after:origin-left after:ease-linear after:duration-300 after:scale-x-0 hover:after:scale-x-100 after:bg-black"
                                >
                                    Affiliates
                                </Link>
                            </li>
                            <li>
                                <Link to={'/hire-us'}
                                    href=""
                                    className="relative py-2.5 dark:text-gray-200 duration-300 ease-linear hover:text-black font-medium after:absolute after:w-full after:left-0 after:bottom-0 after:h-px after:rounded-md after:origin-left after:ease-linear after:duration-300 after:scale-x-0 hover:after:scale-x-100 after:bg-black"
                                >
                                    Hire us
                                </Link>
                            </li>
                            <li className="relative group">
                                <span
                                    className="relative cursor-pointer dark:text-gray-200 py-2.5 duration-300 ease-linear hover:text-black font-medium after:absolute after:w-full after:left-0 after:bottom-0 after:h-px after:rounded-md after:origin-left after:ease-linear after:duration-300 after:scale-x-0 hover:after:scale-x-100 after:bg-black"
                                >
                                    Services
                                </span>
                                <ul className="absolute left-0 w-full md:w-64 rounded-lg mt-0 md:mt-2 z-50 hidden group-hover:flex flex-col bg-white border dark:bg-black dark:border-gray-800 border-gray-200 shadow-md py-2 gap-1.5 transition-all duration-200 ease-in-out">
                                    {sortedMenuItems.map((item) => (
                                        <li className="w-full" key={item.label}>
                                            <Link
                                                to={item.to}
                                                className="px-4 dark:text-gray-200 flex flex-row justify-between items-center py-3 font-medium w-full hover:bg-gray-50 dark:hover:bg-gray-800 text-gray-700 hover:text-gray-900 rounded-md transition-all duration-200 ease-in-out"
                                            >
                                                {item.label}
                                                <ArrowRight size="20" className="dark:text-gray-200" />
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            <li>
                                <Link
                                    to={'/contact-us'}
                                    className="relative py-2.5 dark:text-gray-200 duration-300 ease-linear hover:text-black font-medium after:absolute after:w-full after:left-0 after:bottom-0 after:h-px after:rounded-md after:origin-left after:ease-linear after:duration-300 after:scale-x-0 hover:after:scale-x-100 after:bg-black"
                                >
                                    Contact
                                </Link>
                            </li>
                            {/**<button onClick={handleCartClick} className="relative flex w-full bg-black p-4 border-0 md:bg-transparent md:p-0 md:w-auto lg:w-auto flex-row justify-center items-center rounded-md cursor-pointer transition-all duration-200">
                                <div className="w-full md:w-auto flex flex-row justify-start gap-2">
                                    <ShoppingCart size={18} className="text-white md:text-black" />
                                    <span className="text-white md:hidden">
                                        View my cart
                                    </span>
                                </div>
                                <ArrowRight size={24} className="text-white md:text-black md:hidden" />
                                <span className="absolute top-[-15px] right-[-10px] md:right-[-20px]  mt-1 mr-1 bg-blue-600 text-white text-xs font-bold px-2 py-1 rounded-full">
                                    {totalItems || 0}
                                </span>
                            </button>*/}

                        </ul>

                    </div>
                    <div className="flex items-center lg:hidden">
                        <button
                            onClick={() => {
                                toggleNavbar();
                            }}
                            aria-label="toggle navbar"
                            className="outline-none border-l border-l-indigo-100  pl-3 relative py-3"
                        >
                            <span
                                aria-hidden={true}
                                className={`
                  flex h-0.5 w-6 rounded bg-gray-800 dark:bg-indigo-100  transition duration-300
                  ${navIsOpened ? "rotate-45 translate-y-[.324rem]" : ""}
                `}
                            ></span>
                            <span
                                aria-hidden={true}
                                className={`
                  mt-2 flex h-0.5 w-6 rounded bg-gray-800 dark:bg-indigo-100  transition duration-300
                  ${navIsOpened ? "-rotate-45 -translate-y-[.324rem]" : ""}
                `}
                            />
                        </button>
                    </div>

                </nav>
            </header>
        </>
    );
};

export default Navbar;

import {
    Eye,
    Boxes,
    PlayCircle,
    Camera,
    Compass,
    Layers
} from 'lucide-react'
import { useState } from 'react';
import CallBack from '../components/CallBack';

export default function Design() {

    const [openFAQ, setOpenFAQ] = useState(null);


    // Function to toggle the visibility of FAQ details
    const toggleFAQ = (index) => {
        setOpenFAQ(openFAQ === index ? null : index);
    };


    // Dummy data for FAQs
    const faqs = [
        {
            title: "How long does it take to create a 3D design?",
            content:
                "The duration for creating a 3D design varies based on the project's complexity, the level of detail required, and the intended use. Simple designs may be completed in a few weeks, while intricate and detailed projects can take several months to finalize, including revisions and rendering.",
        },
        {
            title: "What is the cost of 3D design services?",
            content:
                "The cost of 3D design services depends on factors such as the complexity of the design, the level of detail, and the intended use (e.g., animation, visualization). We provide customized quotes based on your specific project requirements and objectives.",
        },
        {
            title: "Can 3D designs be modified after initial creation?",
            content:
                "Yes, 3D designs can be modified and updated even after the initial creation. We offer revision and update services to ensure that the final design meets your expectations and adapts to any changes or new requirements.",
        },
        {
            title: "What types of projects can benefit from 3D design?",
            content:
                "3D design can enhance a wide range of projects, including architectural visualizations, product prototypes, marketing materials, and animated content. It is particularly beneficial for creating realistic and engaging visuals that help communicate complex ideas effectively.",
        },
    ];
    


    const feature = [
        {
            id: 1,
            title: 'Realistic Visuals',
            description: "3D design creates lifelike and detailed visuals that enhance the understanding and appeal of your products or concepts.",
            icon: Eye
        },
        {
            id: 2,
            title: 'Enhanced Prototyping',
            description: "3D models allow for detailed and interactive prototypes, providing a clear representation of your ideas before production.",
            icon: Boxes
        },
        {
            id: 3,
            title: 'Immersive Experiences',
            description: "Utilize 3D design to craft engaging and immersive experiences that captivate your audience and elevate your brand.",
            icon: PlayCircle
        },
        {
            id: 4,
            title: 'Innovative Marketing',
            description: "Leverage 3D visuals in marketing materials to stand out and deliver compelling, memorable content that attracts and retains customers.",
            icon: Camera
        },
        {
            id: 5,
            title: 'Improved Spatial Understanding',
            description: "3D design provides a better spatial understanding of products or environments, helping clients visualize the end result more effectively.",
            icon: Compass
        },
        {
            id: 6,
            title: 'Versatile Applications',
            description: "3D design is versatile, applicable across various fields such as architecture, product design, gaming, and virtual reality.",
            icon: Layers
        }
    ];



    return (
        <main>
            <div className="mx-auto w-full px-5 pb-16 md:px-10 md:py-20">
                <div
                    className="relative flex h-[700px] flex-col gap-4 px-4 py-8 [grid-area:1/1/2/2] md:p-0 md:[grid-area:1/1/2/4]"
                >
                    <div className="absolute bottom-12 left-8 z-20 flex w-64 max-w-lg flex-col items-start rounded-xl bg-white p-6 sm:w-full md:bottom-[10px] md:left-[10px]">
                        <div className="mb-4 rounded-md bg-gray-100 px-2 py-1.5">
                            <p className="text-sm font-semibold text-black">
                                3D Design
                            </p>
                        </div>
                        <p className="mb-4 max-w-xs text-xl font-bold md:text-2xl">
                            Transform your Vision in 3D
                        </p>
                        <small>
                            Discover how our 3D designs can bring your ideas to life. From innovative concepts to impressive details, we take creativity to the next level. Explore the magic of 3D with us!
                        </small>
                        <div className="flex flex-col text-sm text-gray-500 mt-2 lg:flex-row">
                            <p>Ark Designers</p>
                        </div>
                    </div>
                    <video
                        src="images/3dvideo.mp4"
                        autoPlay
                        muted
                        loop
                        className="inline-block h-full w-full object-cover rounded-3xl"
                    />
                </div>
            </div>
            <section>
                {/* Container */}
                <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-20">
                    {/* Title */}
                    <div className="flex flex-col items-center text-center">
                        <h2 className="text-3xl font-bold md:text-5xl">
                            Key Benefits of 3D Design
                        </h2>
                        <p className="mb-8 mt-4 max-w-3xl text-base text-gray-500 md:mb-12 md:text-lg lg:mb-16">
                            3D design delivers realistic and engaging visuals, enhances prototyping, and creates impactful marketing content. It brings concepts to life with depth and detail.
                        </p>
                    </div>
                    {/* Features Content */}
                    <div className="grid gap-5 sm:grid-cols-2 md:grid-cols-3 md:gap-4 lg:gap-6">
                        {/* Features Item */}
                        {
                            feature.map(feactures => {
                                const IconComponent = feactures.icon;
                                return (
                                    <div key={feactures.id} className="grid gap-6 rounded-3xl border border-solid border-gray-300 / 80 p-8 md:p-10">
                                        <div className="w-16 h-16 bg-gray-100 rounded-full flex justify-center items-center transition-transform duration-300 transform hover:scale-105 hover:rotate-3d hover:translate-z-2">
                                            <IconComponent className="w-8 h-8 text-black" />
                                        </div>

                                        <h3 className="text-xl font-semibold">{feactures.title}</h3>
                                        <p className="text-sm text-gray-500">
                                            {feactures.description}
                                        </p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            <section>
                <div className="py-16 md:py-20 mx-auto w-full max-w-7xl px-5 md:px-10">
                    <div className="flex flex-col items-start lg:flex-row lg:space-x-20">
                        <div className="lg:flex-[1_1_500px] w-full flex-none">
                            <div className="max-w-3xl mb-8 md:mb-12 lg:mb-16">
                                <h2 className="font-bold text-3xl md:text-5xl">General FAQs</h2>
                                <div className="mt-4 max-w-lg">
                                    <p className="text-gray-500 text-sm sm:text-base">
                                        Below are some of the most frequently asked questions by our clients, along with their answers, to help you better understand the app development process
                                    </p>
                                </div>
                            </div>
                            <div className="mb-6 h-full w-full overflow-auto bg-gray-100 p-8 rounded-3xl">
                                <div className="flex flex-row gap-4">
                                    <img
                                        src="images/user.png"
                                        alt="Placeholder"
                                        className="inline-block h-12 w-12 object-cover rounded-full"
                                    />
                                    <div className="flex flex-col gap-1.5">
                                        <h5 className="text-xl font-bold">Still have questions?</h5>
                                        <div className="max-w-sm">
                                            <p className="text-gray-500 text-sm sm:text-base">
                                                Can’t find the answer you’re looking for? Please chat to
                                                our support.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-6 mt-8 h-[0.5px] w-full bg-gray-300"></div>
                                <a
                                    href={'/design#call-us'}
                                    className="inline-block items-center rounded-md bg-black px-6 py-3 text-center font-semibold text-white"
                                >
                                    Call me
                                </a>
                            </div>
                        </div>
                        <div className="lg:flex-[1_1_500px] w-full flex-none">
                            {faqs.map((faq, index) => (
                                <div
                                    key={index}
                                    className="mb-6 w-full overflow-hidden bg-gray-100 p-8 rounded-3xl"
                                >
                                    <div
                                        className="flex cursor-pointer items-start justify-between"
                                        onClick={() => toggleFAQ(index)}
                                    >
                                        <p className="text-lg font-semibold">{faq.title}</p>
                                        <div className="relative ml-10 mt-1 flex h-5 w-5 items-center justify-center">
                                            <div
                                                className={`absolute h-5 w-0.5 bg-black transition-transform duration-300 ${openFAQ === index ? "rotate-90" : ""}`}
                                            ></div>

                                            <div className="h-0.5 w-5 bg-black"></div>
                                        </div>
                                    </div>
                                    {openFAQ === index && (
                                        <div className="w-full overflow-hidden mb-4 max-w-2xl lg:max-w-4xl">
                                            <p className="text-sm sm:text-base">{faq.content}</p>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <CallBack />
        </main>
    )
}
import React, { useState } from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import ScrollToTop from '../components/ScrollToTop';
import CartPage from '../pages/CartPage';
import { CartProvider } from '../utils/cartUtils';

const DefaultLayout = ({ children }) => {
  const [openCart, setOpenCart] = useState(false);
  return (
    <>
      <ScrollToTop children={children} />
      <CartProvider>
        <Navbar setOpenCart={setOpenCart} />
        <main className={`${openCart ? "overflow-hidden" : "overflow-hidden"}`}>
          <div className="mx-auto max-w-screen">
            {children}
          </div>
        </main>
        {
          openCart && (
            <div className='w-full md:w-[30%] h-screen border-l-gray-300 border-l-2 z-40 bg-white lg:bg-white/95  backdrop-filter backdrop-blur-2xl fixed top-0 right-0'>
              <CartPage setOpenCart={setOpenCart} />
            </div>
          )
        }
      </CartProvider>
      <Footer />
    </>
  );
};

export default DefaultLayout;

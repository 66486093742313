import {
    DraftingCompass,
    Paintbrush,
    Link,
    Expand, 
    Shield,
    Trophy,
} from 'lucide-react'
import { useState } from 'react';
import CallBack from '../components/CallBack';

export default function Software() {

    const [openFAQ, setOpenFAQ] = useState(null);

    // Function to toggle the visibility of FAQ details
    const toggleFAQ = (index) => {
        setOpenFAQ(openFAQ === index ? null : index);
    };

    // Dummy data for FAQs
    const faqs = [
        {
            title: "What is the typical timeline for software development?",
            content:
                "The development timeline depends on various factors including project complexity, required features, and the technology stack. Generally, a complete software solution can take from 4 weeks to 6 months, encompassing design, development, and testing phases.",
        },
        {
            title: "What factors influence the cost of developing software?",
            content:
                "Software development costs vary based on the complexity of features, design specifications, platform requirements, and any necessary customizations. We offer detailed cost estimates tailored to your specific project needs.",
        },
        {
            title: "Can the software be updated post-launch?",
            content:
                "Yes, we provide continuous support and maintenance services to ensure that your software remains up-to-date and evolves with your business and technological advancements.",
        },
        {
            title: "Is integration with other systems and platforms possible?",
            content:
                "Certainly. We offer integration services to connect your software with various third-party services, APIs, and platforms such as payment gateways, social media channels, and analytics tools, ensuring a unified user experience.",
        },
    ];
    

    const feacture = [
        {
            id: 1,
            title: 'Enhanced Operational Efficiency',
            description: "Custom software streamlines processes and automates tasks, leading to increased productivity and reduced manual effort.",
            icon: DraftingCompass
        },
        {
            id: 2,
            title: 'Tailored Solutions',
            description: 'Software can be specifically designed to meet the unique needs of your business, offering a perfect fit for your operations.',
            icon: Paintbrush
        },
        {
            id: 3,
            title: 'Integration with Existing Systems',
            description: "Custom software integrates seamlessly with your current systems, improving data flow and operational coherence.",
            icon: Link
        },
        {
            id: 4,
            title: 'Scalability and Flexibility',
            description: "Custom software can grow with your business, easily adapting to new requirements and scaling as needed.",
            icon: Expand
        },
        {
            id: 5,
            title: 'Enhanced Data Security',
            description: "Developed with security in mind, custom software provides robust protection against data breaches and cyber threats.",
            icon: Shield
        },
        {
            id: 6,
            title: 'Competitive Advantage',
            description: "Custom software gives you a unique edge by offering features and functionalities not available in off-the-shelf solutions.",
            icon: Trophy
        }
    ];
    


    return (
        <main>
            <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-20">
                {/* Component */}
                <div className="grid grid-cols-1 items-center gap-8 sm:gap-20 lg:grid-cols-2">
                    {/* Heading Div */}
                    <div className="max-w-3xl">
                        <h1 className="mb-6 pb-4 text-4xl font-bold md:text-6xl">
                            Why develop an Software for your business?
                        </h1>
                        <p className="mb-6 max-w-lg text-gray-500 md:mb-10 lg:mb-12">
                        Custom software development provides tailored solutions that align with your specific business needs and goals. It streamlines operations, automates routine tasks, and integrates seamlessly with existing systems. By offering unique features and scalability, custom software helps you stay ahead of competitors and adapt to evolving market demands, ensuring a more efficient and effective business operation.
                        </p>
                        <a
                        href={'/software#call-us'}
                            className="mb-5 mr-5 inline-block rounded-lg bg-black px-6 py-4 text-center font-bold text-white transitionss lg:mb-8 lg:mr-8"
                        >
                            Call Me
                        </a>
                    </div>
                    {/* Image Div */}
                    <div>
                        <img
                            src="images/software.webp"
                            alt=""
                            className="mx-auto inline-block rounded-3xl h-full w-full max-w-2xl object-cover"
                        />
                    </div>
                </div>
            </div>
            <section>
                {/* Container */}
                <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-20">
                    {/* Title */}
                    <div className="flex flex-col items-center text-center">
                        <h2 className="text-3xl font-bold md:text-5xl">
                            Key Benefits of Developing a Business Software
                        </h2>
                        <p className="mb-8 mt-4 max-w-3xl text-base text-gray-500 md:mb-12 md:text-lg lg:mb-16">
                        Developing business software offers tailored solutions that automate tasks, enhance efficiency, and scale with your business growth. It integrates seamlessly with existing systems, providing a competitive edge and improving overall productivity while ensuring robust data security.
                        </p>
                    </div>
                    {/* Features Content */}
                    <div className="grid gap-5 sm:grid-cols-2 md:grid-cols-3 md:gap-4 lg:gap-6">
                        {/* Features Item */}
                        {
                            feacture.map(feactures => {
                                const IconComponent = feactures.icon;
                                return (
                                    <div key={feactures.id} className="grid gap-6 rounded-3xl border border-solid border-gray-300 / 80 p-8 md:p-10">
                                        <div className="w-16 h-16 bg-gray-100 rounded-full flex justify-center items-center">
                                            <IconComponent className="w-8 h-8 text-black" />
                                        </div>
                                        <h3 className="text-xl font-semibold">{feactures.title}</h3>
                                        <p className="text-sm text-gray-500">
                                            {feactures.description}
                                        </p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            <section>
                <div className="py-16 md:py-20 mx-auto w-full max-w-7xl px-5 md:px-10">
                    <div className="flex flex-col items-start lg:flex-row lg:space-x-20">
                        <div className="lg:flex-[1_1_500px] w-full flex-none">
                            <div className="max-w-3xl mb-8 md:mb-12 lg:mb-16">
                                <h2 className="font-bold text-3xl md:text-5xl">General FAQs</h2>
                                <div className="mt-4 max-w-lg">
                                    <p className="text-gray-500 text-sm sm:text-base">
                                        Below are some of the most frequently asked questions by our clients, along with their answers, to help you better understand the app development process
                                    </p>
                                </div>
                            </div>
                            <div className="mb-6 h-full w-full overflow-auto bg-gray-100 p-8 rounded-3xl">
                                <div className="flex flex-row gap-4">
                                    <img
                                        src="images/user.png"
                                        alt="Placeholder"
                                        className="inline-block h-12 w-12 object-cover rounded-full"
                                    />
                                    <div className="flex flex-col gap-1.5">
                                        <h5 className="text-xl font-bold">Still have questions?</h5>
                                        <div className="max-w-sm">
                                            <p className="text-gray-500 text-sm sm:text-base">
                                                Can’t find the answer you’re looking for? Please chat to
                                                our support.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-6 mt-8 h-[0.5px] w-full bg-gray-300"></div>
                                <a
                                    href={'/app#call-us'}
                                    className="inline-block items-center rounded-md bg-black px-6 py-3 text-center font-semibold text-white"
                                >
                                    Call me
                                </a>
                            </div>
                        </div>
                        <div className="lg:flex-[1_1_500px] w-full flex-none">
                            {faqs.map((faq, index) => (
                                <div
                                    key={index}
                                    className="mb-6 w-full overflow-hidden bg-gray-100 p-8 rounded-3xl"
                                >
                                    <div
                                        className="flex cursor-pointer items-start justify-between"
                                        onClick={() => toggleFAQ(index)}
                                    >
                                        <p className="text-lg font-semibold">{faq.title}</p>
                                        <div className="relative ml-10 mt-1 flex h-5 w-5 items-center justify-center">
                                            <div
                                                className={`absolute h-5 w-0.5 bg-black transition-transform duration-300 ${openFAQ === index ? "rotate-90" : ""}`}
                                            ></div>

                                            <div className="h-0.5 w-5 bg-black"></div>
                                        </div>
                                    </div>
                                    {openFAQ === index && (
                                        <div className="w-full overflow-hidden mb-4 max-w-2xl lg:max-w-4xl">
                                            <p className="text-sm sm:text-base">{faq.content}</p>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <CallBack/>
        </main>
    )
}
import { Building, CheckCircle, CheckCheck, ChevronRight, CloudUpload, FileCheck2, FileCode2, FileTerminal, FolderArchive, Images, Home, MessageCircle } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { db, storage } from '../private/FirebaseConfig';  // Asegúrate de importar las configuraciones de Firebase
import { ref, uploadBytes } from 'firebase/storage';
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import PageNotFound from './PageNotFound';

const UploadDocuments = () => {
  const [openJobs, setOpenJobs] = useState([]);
  const location = useLocation();
  const [dataCompleted, setDataCompleted] = useState(false);

  const databases = {
    SQL: [
      {
        id: 1,
        name: 'MySQL'
      },
      {
        id: 2,
        name: 'PostgreSQL'
      },
      {
        id: 3,
        name: 'SQLite'
      },
      {
        id: 5,
        name: 'Microsoft SQL Server'
      },
      {
        id: 6,
        name: 'Oracle Database'
      }
    ],
    NoSQL: [
      {
        id: 4,
        name: 'MongoDB'
      },
      {
        id: 7,
        name: 'Redis'
      },
      {
        id: 8,
        name: 'Cassandra'
      },
      {
        id: 9,
        name: 'Elasticsearch'
      },
      {
        id: 10,
        name: 'Firebase'
      }
    ]
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "projects"));
        const jobsData = [];
        querySnapshot.forEach((doc) => {
          jobsData.push({ id: doc.id, ...doc.data() });
          if (doc.data().data === true) {
            setDataCompleted(true);
          } else {
            setDataCompleted(false);
          }
        });
        setOpenJobs(jobsData);
      } catch (error) {
      }
    };

    fetchData();
  }, []);

  // Obtener el ID del trabajo desde la URL
  const query = new URLSearchParams(location.search);
  const id = query.get('com.arkplatforms.id'); // Corregido aquí


  // Buscar el trabajo correspondiente al ID
  const job = openJobs.find((job) => job.id === id);

  useEffect(() => {
    if (job) {
      // Verificar si job.email está presente
      if (job.email) {
        setFormData(prevFormData => ({
          ...prevFormData,
          email: job.email,
          phone: job.phone,
        }));
      } else {
        // Aquí puedes lanzar un alerta o manejar el caso en que el correo no esté presente
        console.warn("El correo electrónico no está disponible en job");
        // También puedes establecer un valor predeterminado si lo deseas
        // setFormData(prevFormData => ({ ...prevFormData, email: 'default@example.com' }));
      }

      // Guardar información adicional en localStorage
      localStorage.setItem('name', job.business);
      localStorage.setItem('id', job.id);
    }
  }, [job]);

  const [submitted, setSubmitted] = useState(false);
  const [uploading, setUploading] = useState(false); // Nuevo estado para manejar el estado de carga

  const [formData, setFormData] = useState({
    appName: '',
    database: '',
    language: '',
    email: '', // Ensure it's initialized
    phone: '',
    anotations: '',
    resume: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const uploadFile = async (file, folder) => {
    if (!file) {
      return;
    }

    try {
      const storageRef = ref(storage, `${folder}/${file.name}`);
      await uploadBytes(storageRef, file);
    } catch (error) {
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const folderName = localStorage.getItem('name'); // Obtener el nombre de la carpeta

    if (file) {
      try {
        // Aquí asumo que `uploadFile` devuelve una promesa
        await uploadFile(file, folderName);
        setFormData(prevFormData => ({
          ...prevFormData,  // Copiar las propiedades anteriores
          resume: true      // Actualizar solo la propiedad resume
        }));
      } catch (error) {
        console.error('Error al subir el archivo:', error);
      }
    } else {
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);

    try {
      // Create a reference to the document you want to update
      const docRef = doc(db, 'projects', localStorage.getItem('id')); // Replace 'id' with the document ID you want to update

      // Update the document in Firestore
      await updateDoc(docRef, {
        database: formData.database,
        language: formData.language,
        email: formData.email,
        phone: formData.phone,
        anotations: formData.anotations,
        appName: formData.appName,
        data: true
      });

      setSubmitted(true);
    } catch (error) {
      console.error('Error updating document: ', error);
    } finally {
      setUploading(false);
    }
  };

  if (!job) {
    return <p className="text-gray-500 text-center">Job not found.</p>;
  }

  const getState = (job) => {
    switch (job) {
      case 'pending':
        return (
          <span className="inline-block px-6 my-4 md:my-0 py-4 text-sm font-semibold text-yellow-700 bg-yellow-100 rounded-full">
            Pending
          </span>
        );
      case 'accepted':
        return (
          <span className="inline-block px-6 my-4 md:my-0 py-4 text-sm font-semibold text-green-600 bg-green-100 rounded-full">
            Accepted
          </span>
        );
      case 'rejected':
        return (
          <span className="inline-block px-6 my-4 md:my-0 py-4 text-sm font-semibold text-red-600 bg-red-100 rounded-full">
            Rejected
          </span>
        );
      case 'In Process':
        return (
          <span className="inline-block px-6 my-4 md:my-0 py-4 text-sm font-semibold text-blue-600 bg-blue-100 rounded-full">
            In Process
          </span>
        );
      default:
        return ('Error')
    }
  }

  const developmentLanguages = {
    app: [
      "React Native - Cross platform", // Desarrollo móvil multiplataforma
      "Kotlin",      // Desarrollo de apps nativas para Android
      "Java",        // Aplicaciones móviles nativas (Android)
      "Swift - Only for Apple Apps"        // Desarrollo de apps nativas para iOS
    ],
    software: [
      "C#",          // Desarrollo de aplicaciones de escritorio y backend (.NET)
      "Java",        // Aplicaciones de escritorio, backend, y móviles
      "Python",      // Backend, automatización, ciencia de datos, IA
      "Node.js",     // Backend para aplicaciones web y móviles
      "Electron",    // Aplicaciones de escritorio multiplataforma con tecnologías web
      "C++",         // Desarrollo de software de alto rendimiento y juegos
    ],
    web: [
      "HTML + CSS + JavaScript", // Tecnologías básicas para desarrollo web
      "Next.js",    // Framework de React.js para renderizado del lado del servidor
      "React.js",   // Librería de JavaScript para interfaces de usuario
      "Astro.js",   // Framework estático para desarrollo web moderno
      "Vite.js",     // Herramienta de construcción rápida para proyectos web
      "PHP",
      "Angular",
      "Laravel"
    ]
  };


  const getType = (model) => {
    switch (model) {
      case 'app':
        // Retorna las opciones de desarrollo de aplicaciones móviles
        return developmentLanguages.app.map((language) => ({
          value: language.toLowerCase().replace(/\s+/g, '-'),
          label: language
        }));
      case 'software':
        return developmentLanguages.software.map((language) => ({
          value: language.toLowerCase().replace(/\s+/g, '-'),
          label: language
        }));
      case 'web':
        return developmentLanguages.web.map((language) => ({
          value: language.toLowerCase().replace(/\s+/g, '-'),
          label: language
        }));
      default:
        return 'Unknown';
    }
  };

  const uploadExecute = (file) => {
    switch (file) {
      case 'app':
        return (
          <input onChange={handleFileChange} type="file" className="hidden" id="executable"
            name="executable"
            accept=".apk,.ipa"
          />
        )
      case 'software':
        return (
          <input
            onChange={handleFileChange}
            type="file"
            className="hidden"
            id="executable"
            name="executable"
            accept=".exe,.msi,.dmg,.app,.sh" // Adjust accepted file types as needed
          />
        );

      case 'web':
        return (
          <input
            onChange={handleFileChange}
            type="file"
            className="hidden"
            id="executable"
            name="executable"
            accept=".html,.css,.js" // Adjust accepted file types as needed
          />
        );

      default:
        return null;
    }
  }


  if (submitted) {
    return (
      <div className="min-h-screen flex items-center justify-center ">
        <div className="bg-white p-8 max-w-lg">
          <CheckCheck className='my-3 size-10' />
          <h2 className="text-4xl font-semibold text-gray-800 mb-6">Application Sent!</h2>
          <p className="text-lg text-gray-700 mb-2">
            Thank you for sharing some more information about the application with us.
          </p>
          <p className="text-lg text-gray-700 mb-6">
            We remind you that the delivery time for this application is until the day {job.finalDate}
          </p>
          <small>
            For any questions or queries you can send us an email to <br /> <Link><span className='font-semibold'>Info@ark-platforms.com</span></Link><br />
            Response times can be from 24 to 72 hours, please be patient during this process.
          </small>
          <Link to={'/'}>
            <button
              type="button"
              className="w-full px-6 mt-10 py-4 bg-gray-900 float-end text-white font-semibold rounded-lg transition duration-300 hover:bg-gray-800 flex flex-row justify-between items-center"
            >
              Go to Home
              <ChevronRight />
            </button>
          </Link>
        </div>
      </div>
    );
  }

  return (
    <section>
      {
        dataCompleted ?
          <>
            <div className='min-h-[50vh] md:max-w-7xl mx-auto flex justify-start py-8'>
              <PageNotFound/>
            </div>
          </>
          :
          <>
            <div className="min-h-screen flex justify-center py-8">
              {<div className="w-full md:max-w-7xl">
                <div className="bg-white py-10 pt-0 px-5">
                  <div className='flex flex-col md:flex-row md:justify-between md:items-center'>
                    <h2 className="text-3xl md:text-4xl font-bold text-gray-800 flex md:items-center gap-4"><Building className='size-10' />{job.business}</h2>
                    {getState(job.state)}
                  </div>
                  <small>
                    Possible delivery: {job.finalDate}
                  </small>
                  <p className="text-base text-gray-700 mb-8 mt-4">
                    Work ID:  <span className='font-semibold text-gray-800'>{job.id}</span>.
                  </p>

                  <form onSubmit={handleSubmit} className="space-y-6">
                    {/* Form fields */}
                    <div className='w-full flex flex-col md:flex-row gap-4'>
                      <div className='w-full'>
                        <label className="block text-gray-700 capitalize font-semibold mb-2" htmlFor="appName">
                          {job.type} name
                        </label>
                        <input
                          type="text"
                          id="appName"
                          name="appName"
                          value={formData.appName || ''}
                          onChange={handleChange}
                          required
                          className="w-full px-4 py-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-0"
                        />
                      </div>
                      <div className='w-full'>
                        <label className="block text-gray-700 font-semibold mb-2" htmlFor="database">
                          Database
                        </label>
                        <select id="database"
                          name="database"
                          value={formData.database || 'x'}
                          onChange={handleChange} className="w-full px-4 py-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-0">
                          <option disabled hidden value={'x'}>Select a Database</option>
                          {Object.entries(databases).map(([group, dbs]) => (
                            <optgroup key={group} label={group} className='inter'>
                              {dbs.map((database) => (
                                <option key={database.id} value={database.name} className='inter'>
                                  {database.name}
                                </option>
                              ))}
                            </optgroup>
                          ))}
                        </select>
                      </div>
                      <div className='w-full'>
                        <label className="block text-gray-700 font-semibold mb-2" htmlFor="language">
                          Development Language
                        </label>
                        <select
                          id="language"
                          name="language"
                          value={formData.language || "x"} // Use value instead of value
                          onChange={handleChange}
                          className="w-full px-4 py-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-0"
                        >
                          <option value="x" disabled hidden>
                            Select a Database
                          </option>
                          {getType(job.type).map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>

                      </div>
                    </div>

                    <div className='w-full flex flex-col md:flex-row gap-4'>
                      <div className='w-full'>
                        <label className="block text-gray-700 font-semibold mb-2" htmlFor="email">
                          Email Address
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={formData.email || ''}
                          onChange={handleChange}
                          required
                          className="w-full px-4 py-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-0"
                        />
                      </div>
                      <div className='w-full'>
                        <label className="block text-gray-700 font-semibold mb-2" htmlFor="phone">
                          Phone Number
                        </label>
                        <input
                          type="tel"
                          id="phone"
                          name="phone"
                          value={formData.phone || ''}
                          onChange={handleChange}
                          required
                          className="w-full px-4 py-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-0"
                        />
                        <small className='font-semibold text-gray-400'>
                          Please add you country prefix (+00)
                        </small>
                      </div>
                    </div>
                    {/* Subir CV */}
                    <div className="flex flex-col md:flex-row items-center justify-center w-full gap-4">
                      <label htmlFor="resume" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100">
                        {
                          formData.resume ? (
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                              <FolderArchive className="text-gray-500 size-12" />
                              <p className="mb-2 text-lg text-gray-800 font-semibold">Document uploaded successfully</p>
                              <p className="text-xs text-gray-800 font-normal text-center">Thank you very much for sharing your CV with us, <br /> please don't share information such as Identity Documents, Driving License among others...</p>
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                              <FolderArchive className="text-gray-500 size-12" />
                              <p className="mb-2 text-sm text-gray-500 mt-4 "><span className="font-semibold">Click to upload</span></p>
                            </div>
                          )
                        }
                        <input onChange={handleFileChange} type="file" className="hidden" id="resume"
                          name="resume" accept='.zip, .rar'
                        />
                      </label>
                    </div>
                    <small>

                    </small>

                    <div>
                      <label className="block text-gray-700 font-semibold mb-2" htmlFor="anotations">
                        Additional notes
                      </label>
                      <textarea
                        id="anotations"
                        name="anotations"
                        value={formData.anotations}
                        onChange={handleChange}
                        rows="4"
                        className="w-full px-4 py-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-0"
                      />
                    </div>

                    {/* Botón de envío */}
                    <button
                      type="submit"
                      disabled={uploading}  // Deshabilitar el botón mientras se está subiendo el archivo
                      className="w-full md:w-1/2 px-6 py-4 bg-gray-900 float-end text-white font-semibold rounded-lg transition duration-300 hover:bg-gray-800 flex flex-row justify-between items-center"
                    >
                      {uploading ? 'Saving...' : 'Save changes'}
                      <ChevronRight />
                    </button>
                  </form>
                </div>
              </div>}
            </div>
          </>
      }
    </section>
  );
};

export default UploadDocuments;
